.timeline-container {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
}
.timeline {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
}
.timeline::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: #cfd8dc;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}
.timeline li {
    position: relative;
    margin: 20px 0;
    width: 50%;
    padding-left: 40px;
    box-sizing: border-box;
}
.timeline li:nth-child(odd) {
    left: 0;
    text-align: right;
    padding-right: 40px;
    padding-left: 0;
}
.timeline li:nth-child(even) {
    left: 50%;
    text-align: left;
}
.timeline li .marker {
    position: absolute;
    left: calc(50% - 10px);
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #607d8b;
    border-radius: 50%;
    z-index: 1;
}
.timeline li.completed .marker {
    background-color: #4caf50;
}
.timeline li.milestone .marker {
    background-color: #f44336;
}
.timeline li .content {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.timeline li .content h4 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
}
.timeline li .content p {
    margin: 5px 0 0;
    font-size: 14px;
}