@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url('fonts/opensans/opensans-light.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('fonts/opensans/opensans.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('fonts/opensans/opensans-bold.woff') format('woff');
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

@media print {
  * {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
  }
  a,
  a:visited {
      text-decoration: underline;
  }
  a[href]:after {
      content: " (" attr(href) ")";
  }
  abbr[title]:after {
      content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
      content: "";
  }
  pre,
  blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
  }
  thead {
      display: table-header-group;
  }
  tr,
  img {
      page-break-inside: avoid;
  }
  img {
      max-width: 100% !important;
  }
  p,
  h2,
  h3 {
      orphans: 3;
      widows: 3;
  }
  h2,
  h3 {
      page-break-after: avoid;
  }
  select {
      background: #fff !important;
  }
  .navbar {
      display: none;
  }
  .table td,
  .table th {
      background-color: #fff !important;
  }
  .btn>.caret,
  .dropup>.btn>.caret {
      border-top-color: #000 !important;
  }
  .label {
      border: 1px solid #000;
  }
  .table {
      border-collapse: collapse !important;
  }
  .table-bordered th,
  .table-bordered td {
      border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('fonts/glyphicons-halflings-regular.html');
  src: url('fonts/glyphicons-halflings-regulard41d.html?#iefix') format('embedded-opentype'), url('fonts/glyphicons-halflings-regular-2.html') format('woff'), url('fonts/glyphicons-halflings-regular-3.html') format('truetype'), url('fonts/glyphicons-halflings-regular-4.html#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "\2a";
}

.glyphicon-plus:before {
  content: "\2b";
}

.glyphicon-euro:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-bookmark:before {
  content: "\e044";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-camera:before {
  content: "\e046";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-fire:before {
  content: "\e104";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-bell:before {
  content: "\e123";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-wrench:before {
  content: "\e136";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-briefcase:before {
  content: "\e139";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-paperclip:before {
  content: "\e142";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-pushpin:before {
  content: "\e146";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #428bca;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #2a6496;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  display: inline-block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
      font-size: 21px;
  }
}

small,
.small {
  font-size: 85%;
}

cite {
  font-style: normal;
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #428bca;
}

a.text-primary:hover {
  color: #3071a9;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #428bca;
}

a.bg-primary:hover {
  background-color: #3071a9;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.42857143;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .dl-horizontal dd {
      margin-left: 180px;
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}

.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
  content: '';
}

.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}

blockquote:before,
blockquote:after {
  content: "";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
      width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
      width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
      width: 1170px;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
      float: left;
  }
  .col-sm-12 {
      width: 100%;
  }
  .col-sm-11 {
      width: 91.66666667%;
  }
  .col-sm-10 {
      width: 83.33333333%;
  }
  .col-sm-9 {
      width: 75%;
  }
  .col-sm-8 {
      width: 66.66666667%;
  }
  .col-sm-7 {
      width: 58.33333333%;
  }
  .col-sm-6 {
      width: 50%;
  }
  .col-sm-5 {
      width: 41.66666667%;
  }
  .col-sm-4 {
      width: 33.33333333%;
  }
  .col-sm-3 {
      width: 25%;
  }
  .col-sm-2 {
      width: 16.66666667%;
  }
  .col-sm-1 {
      width: 8.33333333%;
  }
  .col-sm-pull-12 {
      right: 100%;
  }
  .col-sm-pull-11 {
      right: 91.66666667%;
  }
  .col-sm-pull-10 {
      right: 83.33333333%;
  }
  .col-sm-pull-9 {
      right: 75%;
  }
  .col-sm-pull-8 {
      right: 66.66666667%;
  }
  .col-sm-pull-7 {
      right: 58.33333333%;
  }
  .col-sm-pull-6 {
      right: 50%;
  }
  .col-sm-pull-5 {
      right: 41.66666667%;
  }
  .col-sm-pull-4 {
      right: 33.33333333%;
  }
  .col-sm-pull-3 {
      right: 25%;
  }
  .col-sm-pull-2 {
      right: 16.66666667%;
  }
  .col-sm-pull-1 {
      right: 8.33333333%;
  }
  .col-sm-pull-0 {
      right: auto;
  }
  .col-sm-push-12 {
      left: 100%;
  }
  .col-sm-push-11 {
      left: 91.66666667%;
  }
  .col-sm-push-10 {
      left: 83.33333333%;
  }
  .col-sm-push-9 {
      left: 75%;
  }
  .col-sm-push-8 {
      left: 66.66666667%;
  }
  .col-sm-push-7 {
      left: 58.33333333%;
  }
  .col-sm-push-6 {
      left: 50%;
  }
  .col-sm-push-5 {
      left: 41.66666667%;
  }
  .col-sm-push-4 {
      left: 33.33333333%;
  }
  .col-sm-push-3 {
      left: 25%;
  }
  .col-sm-push-2 {
      left: 16.66666667%;
  }
  .col-sm-push-1 {
      left: 8.33333333%;
  }
  .col-sm-push-0 {
      left: auto;
  }
  .col-sm-offset-12 {
      margin-left: 100%;
  }
  .col-sm-offset-11 {
      margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
      margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
      margin-left: 75%;
  }
  .col-sm-offset-8 {
      margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
      margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
      margin-left: 50%;
  }
  .col-sm-offset-5 {
      margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
      margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
      margin-left: 25%;
  }
  .col-sm-offset-2 {
      margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
      margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
      margin-left: 0;
  }
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
      float: left;
  }
  .col-md-12 {
      width: 100%;
  }
  .col-md-11 {
      width: 91.66666667%;
  }
  .col-md-10 {
      width: 83.33333333%;
  }
  .col-md-9 {
      width: 75%;
  }
  .col-md-8 {
      width: 66.66666667%;
  }
  .col-md-7 {
      width: 58.33333333%;
  }
  .col-md-6 {
      width: 50%;
  }
  .col-md-5 {
      width: 41.66666667%;
  }
  .col-md-4 {
      width: 33.33333333%;
  }
  .col-md-3 {
      width: 25%;
  }
  .col-md-2 {
      width: 16.66666667%;
  }
  .col-md-1 {
      width: 8.33333333%;
  }
  .col-md-pull-12 {
      right: 100%;
  }
  .col-md-pull-11 {
      right: 91.66666667%;
  }
  .col-md-pull-10 {
      right: 83.33333333%;
  }
  .col-md-pull-9 {
      right: 75%;
  }
  .col-md-pull-8 {
      right: 66.66666667%;
  }
  .col-md-pull-7 {
      right: 58.33333333%;
  }
  .col-md-pull-6 {
      right: 50%;
  }
  .col-md-pull-5 {
      right: 41.66666667%;
  }
  .col-md-pull-4 {
      right: 33.33333333%;
  }
  .col-md-pull-3 {
      right: 25%;
  }
  .col-md-pull-2 {
      right: 16.66666667%;
  }
  .col-md-pull-1 {
      right: 8.33333333%;
  }
  .col-md-pull-0 {
      right: auto;
  }
  .col-md-push-12 {
      left: 100%;
  }
  .col-md-push-11 {
      left: 91.66666667%;
  }
  .col-md-push-10 {
      left: 83.33333333%;
  }
  .col-md-push-9 {
      left: 75%;
  }
  .col-md-push-8 {
      left: 66.66666667%;
  }
  .col-md-push-7 {
      left: 58.33333333%;
  }
  .col-md-push-6 {
      left: 50%;
  }
  .col-md-push-5 {
      left: 41.66666667%;
  }
  .col-md-push-4 {
      left: 33.33333333%;
  }
  .col-md-push-3 {
      left: 25%;
  }
  .col-md-push-2 {
      left: 16.66666667%;
  }
  .col-md-push-1 {
      left: 8.33333333%;
  }
  .col-md-push-0 {
      left: auto;
  }
  .col-md-offset-12 {
      margin-left: 100%;
  }
  .col-md-offset-11 {
      margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
      margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
      margin-left: 75%;
  }
  .col-md-offset-8 {
      margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
      margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
      margin-left: 50%;
  }
  .col-md-offset-5 {
      margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
      margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
      margin-left: 25%;
  }
  .col-md-offset-2 {
      margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
      margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
      margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
      float: left;
  }
  .col-lg-12 {
      width: 100%;
  }
  .col-lg-11 {
      width: 91.66666667%;
  }
  .col-lg-10 {
      width: 83.33333333%;
  }
  .col-lg-9 {
      width: 75%;
  }
  .col-lg-8 {
      width: 66.66666667%;
  }
  .col-lg-7 {
      width: 58.33333333%;
  }
  .col-lg-6 {
      width: 50%;
  }
  .col-lg-5 {
      width: 41.66666667%;
  }
  .col-lg-4 {
      width: 33.33333333%;
  }
  .col-lg-3 {
      width: 25%;
  }
  .col-lg-2 {
      width: 16.66666667%;
  }
  .col-lg-1 {
      width: 8.33333333%;
  }
  .col-lg-pull-12 {
      right: 100%;
  }
  .col-lg-pull-11 {
      right: 91.66666667%;
  }
  .col-lg-pull-10 {
      right: 83.33333333%;
  }
  .col-lg-pull-9 {
      right: 75%;
  }
  .col-lg-pull-8 {
      right: 66.66666667%;
  }
  .col-lg-pull-7 {
      right: 58.33333333%;
  }
  .col-lg-pull-6 {
      right: 50%;
  }
  .col-lg-pull-5 {
      right: 41.66666667%;
  }
  .col-lg-pull-4 {
      right: 33.33333333%;
  }
  .col-lg-pull-3 {
      right: 25%;
  }
  .col-lg-pull-2 {
      right: 16.66666667%;
  }
  .col-lg-pull-1 {
      right: 8.33333333%;
  }
  .col-lg-pull-0 {
      right: auto;
  }
  .col-lg-push-12 {
      left: 100%;
  }
  .col-lg-push-11 {
      left: 91.66666667%;
  }
  .col-lg-push-10 {
      left: 83.33333333%;
  }
  .col-lg-push-9 {
      left: 75%;
  }
  .col-lg-push-8 {
      left: 66.66666667%;
  }
  .col-lg-push-7 {
      left: 58.33333333%;
  }
  .col-lg-push-6 {
      left: 50%;
  }
  .col-lg-push-5 {
      left: 41.66666667%;
  }
  .col-lg-push-4 {
      left: 33.33333333%;
  }
  .col-lg-push-3 {
      left: 25%;
  }
  .col-lg-push-2 {
      left: 16.66666667%;
  }
  .col-lg-push-1 {
      left: 8.33333333%;
  }
  .col-lg-push-0 {
      left: auto;
  }
  .col-lg-offset-12 {
      margin-left: 100%;
  }
  .col-lg-offset-11 {
      margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
      margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
      margin-left: 75%;
  }
  .col-lg-offset-8 {
      margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
      margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
      margin-left: 50%;
  }
  .col-lg-offset-5 {
      margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
      margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
      margin-left: 25%;
  }
  .col-lg-offset-2 {
      margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
      margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
      margin-left: 0;
  }
}

table {
  background-color: transparent;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
  border-top: 0;
}

.table>tbody+tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  border: 1px solid #ddd;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
  border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover>td,
.table-hover>tbody>tr:hover>th {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
  background-color: #f5f5f5;
}

.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover,
.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr.active:hover>th {
  background-color: #e8e8e8;
}

.table>thead>tr>td.success,
.table>tbody>tr>td.success,
.table>tfoot>tr>td.success,
.table>thead>tr>th.success,
.table>tbody>tr>th.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>tbody>tr.success>td,
.table>tfoot>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr.success>th,
.table>tfoot>tr.success>th {
  background-color: #dff0d8;
}

.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover,
.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr.success:hover>th {
  background-color: #d0e9c6;
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
  background-color: #d9edf7;
}

.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover,
.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr.info:hover>th {
  background-color: #c4e3f3;
}

.table>thead>tr>td.warning,
.table>tbody>tr>td.warning,
.table>tfoot>tr>td.warning,
.table>thead>tr>th.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>tbody>tr.warning>td,
.table>tfoot>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr.warning>th,
.table>tfoot>tr.warning>th {
  background-color: #fcf8e3;
}

.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover,
.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr.warning:hover>th {
  background-color: #faf2cc;
}

.table>thead>tr>td.danger,
.table>tbody>tr>td.danger,
.table>tfoot>tr>td.danger,
.table>thead>tr>th.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>tbody>tr.danger>td,
.table>tfoot>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr.danger>th,
.table>tfoot>tr.danger>th {
  background-color: #f2dede;
}

.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover,
.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr.danger:hover>th {
  background-color: #ebcccc;
}

@media screen and (max-width: 767px) {
  .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
  }
  .table-responsive>.table {
      margin-bottom: 0;
  }
  .table-responsive>.table>thead>tr>th,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tfoot>tr>td {
      white-space: nowrap;
  }
  .table-responsive>.table-bordered {
      border: 0;
  }
  .table-responsive>.table-bordered>thead>tr>th:first-child,
  .table-responsive>.table-bordered>tbody>tr>th:first-child,
  .table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .table-responsive>.table-bordered>thead>tr>td:first-child,
  .table-responsive>.table-bordered>tbody>tr>td:first-child,
  .table-responsive>.table-bordered>tfoot>tr>td:first-child {
      border-left: 0;
  }
  .table-responsive>.table-bordered>thead>tr>th:last-child,
  .table-responsive>.table-bordered>tbody>tr>th:last-child,
  .table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .table-responsive>.table-bordered>thead>tr>td:last-child,
  .table-responsive>.table-bordered>tbody>tr>td:last-child,
  .table-responsive>.table-bordered>tfoot>tr>td:last-child {
      border-right: 0;
  }
  .table-responsive>.table-bordered>tbody>tr:last-child>th,
  .table-responsive>.table-bordered>tfoot>tr:last-child>th,
  .table-responsive>.table-bordered>tbody>tr:last-child>td,
  .table-responsive>.table-bordered>tfoot>tr:last-child>td {
      border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control::-moz-placeholder {
  color: #777;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #777;
}

.form-control::-webkit-input-placeholder {
  color: #777;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 34px;
  line-height: 1.42857143 \0;
}

input[type="date"].input-sm,
input[type="time"].input-sm,
input[type="datetime-local"].input-sm,
input[type="month"].input-sm {
  line-height: 30px;
}

input[type="date"].input-lg,
input[type="time"].input-lg,
input[type="datetime-local"].input-lg,
input[type="month"].input-lg {
  line-height: 46px;
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio+.radio,
.checkbox+.checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.input-sm,
.form-horizontal .form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.input-lg+.form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm+.form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
  }
  .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
  }
  .form-inline .input-group {
      display: inline-table;
      vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
      width: auto;
  }
  .form-inline .input-group>.form-control {
      width: 100%;
  }
  .form-inline .control-label {
      margin-bottom: 0;
      vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
      padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
      top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  top: 0;
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
      padding-top: 14.3px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
      padding-top: 6px;
  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #3071a9;
  border-color: #285e8e;
}

.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}

.btn-primary .badge {
  color: #428bca;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open>.dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active,
.btn-success.active,
.open>.dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open>.dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active,
.btn-info.active,
.open>.dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open>.dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active,
.btn-warning.active,
.open>.dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open>.dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active,
.btn-danger.active,
.open>.dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  font-weight: normal;
  color: #428bca;
  cursor: pointer;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}

.btn-link:hover,
.btn-link:focus {
  color: #2a6496;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777;
  text-decoration: none;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height .35s ease;
  -o-transition: height .35s ease;
  transition: height .35s ease;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #428bca;
  outline: 0;
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  color: #777;
}

.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open>.dropdown-menu {
  display: block;
}

.open>a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px solid;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
      right: 0;
      left: auto;
  }
  .navbar-right .dropdown-menu-left {
      right: auto;
      left: 0;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  float: left;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover,
.btn-group>.btn:focus,
.btn-group-vertical>.btn:focus,
.btn-group>.btn:active,
.btn-group-vertical>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn.active {
  z-index: 2;
}

.btn-group>.btn:focus,
.btn-group-vertical>.btn:focus {
  outline: 0;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
  margin-left: 5px;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-group {
  float: left;
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0;
}

.btn-group>.btn-group:first-child>.btn:last-child,
.btn-group>.btn-group:first-child>.dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:last-child>.btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group>.btn+.dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}

.btn-group>.btn-lg+.dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical>.btn-group>.btn {
  float: none;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0;
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}

.btn-group-justified>.btn-group .btn {
  width: 100%;
}

.btn-group-justified>.btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"]>.btn>input[type="radio"],
[data-toggle="buttons"]>.btn>input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  filter: alpha(opacity=0);
  opacity: 0;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-group-lg>.form-control,
textarea.input-group-lg>.input-group-addon,
textarea.input-group-lg>.input-group-btn>.btn,
select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-addon,
select[multiple].input-group-lg>.input-group-btn>.btn {
  height: auto;
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-group-sm>.form-control,
textarea.input-group-sm>.input-group-addon,
textarea.input-group-sm>.input-group-btn>.btn,
select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-addon,
select[multiple].input-group-sm>.input-group-btn>.btn {
  height: auto;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}

.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn>.btn {
  position: relative;
}

.input-group-btn>.btn+.btn {
  margin-left: -1px;
}

.input-group-btn>.btn:hover,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:active {
  z-index: 2;
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  margin-left: -1px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav>li {
  position: relative;
  display: block;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav>li>a:hover,
.nav>li>a:focus {
  text-decoration: none;
  background-color: #eee;
}

.nav>li.disabled>a {
  color: #777;
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: #eee;
  border-color: #428bca;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav>li>a>img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs>li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs>li>a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.nav-tabs.nav-justified>li {
  float: none;
}

.nav-tabs.nav-justified>li>a {
  margin-bottom: 5px;
  text-align: center;
}

.nav-tabs.nav-justified>.dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified>li {
      display: table-cell;
      width: 1%;
  }
  .nav-tabs.nav-justified>li>a {
      margin-bottom: 0;
  }
}

.nav-tabs.nav-justified>li>a {
  margin-right: 0;
  border-radius: 4px;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified>li>a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
  }
  .nav-tabs.nav-justified>.active>a,
  .nav-tabs.nav-justified>.active>a:hover,
  .nav-tabs.nav-justified>.active>a:focus {
      border-bottom-color: #fff;
  }
}

.nav-pills>li {
  float: left;
}

.nav-pills>li>a {
  border-radius: 4px;
}

.nav-pills>li+li {
  margin-left: 2px;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
  color: #fff;
  background-color: #428bca;
}

.nav-stacked>li {
  float: none;
}

.nav-stacked>li+li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified {
  width: 100%;
}

.nav-justified>li {
  float: none;
}

.nav-justified>li>a {
  margin-bottom: 5px;
  text-align: center;
}

.nav-justified>.dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified>li {
      display: table-cell;
      width: 1%;
  }
  .nav-justified>li>a {
      margin-bottom: 0;
  }
}

.nav-tabs-justified {
  border-bottom: 0;
}

.nav-tabs-justified>li>a {
  margin-right: 0;
  border-radius: 4px;
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:hover,
.nav-tabs-justified>.active>a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified>li>a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
  }
  .nav-tabs-justified>.active>a,
  .nav-tabs-justified>.active>a:hover,
  .nav-tabs-justified>.active>a:focus {
      border-bottom-color: #fff;
  }
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
      border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
      float: left;
  }
}

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
      width: auto;
      border-top: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .navbar-collapse.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
  }
  .navbar-collapse.in {
      overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
  }
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
      max-height: 200px;
  }
}

.container>.navbar-header,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .container>.navbar-header,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container-fluid>.navbar-collapse {
      margin-right: 0;
      margin-left: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .navbar-static-top {
      border-radius: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
      border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .navbar>.container .navbar-brand,
  .navbar>.container-fluid .navbar-brand {
      margin-left: -15px;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
      display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu>li>a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu>li>a {
      line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-nav .open .dropdown-menu>li>a:focus {
      background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
      float: left;
      margin: 0;
  }
  .navbar-nav>li {
      float: left;
  }
  .navbar-nav>li>a {
      padding-top: 15px;
      padding-bottom: 15px;
  }
  .navbar-nav.navbar-right:last-child {
      margin-right: -15px;
  }
}

@media (min-width: 768px) {
  .navbar-left {
      float: left !important;
  }
  .navbar-right {
      float: right !important;
  }
}

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
}

@media (min-width: 768px) {
  .navbar-form .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
  }
  .navbar-form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
  }
  .navbar-form .input-group {
      display: inline-table;
      vertical-align: middle;
  }
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn,
  .navbar-form .input-group .form-control {
      width: auto;
  }
  .navbar-form .input-group>.form-control {
      width: 100%;
  }
  .navbar-form .control-label {
      margin-bottom: 0;
      vertical-align: middle;
  }
  .navbar-form .radio,
  .navbar-form .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
  }
  .navbar-form .radio label,
  .navbar-form .checkbox label {
      padding-left: 0;
  }
  .navbar-form .radio input[type="radio"],
  .navbar-form .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
  }
  .navbar-form .has-feedback .form-control-feedback {
      top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
      margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-form {
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
      margin-left: 0;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .navbar-form.navbar-right:last-child {
      margin-right: -15px;
  }
}

.navbar-nav>li>.dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .navbar-text {
      float: left;
      margin-right: 15px;
      margin-left: 15px;
  }
  .navbar-text.navbar-right:last-child {
      margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}

.navbar-default .navbar-text {
  color: #777;
}

.navbar-default .navbar-nav>li>a {
  color: #777;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
  color: #333;
  background-color: transparent;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:hover,
.navbar-default .navbar-nav>.disabled>a:focus {
  color: #ccc;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
  color: #555;
  background-color: #e7e7e7;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
      color: #777;
  }
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
      color: #333;
      background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
      color: #555;
      background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus {
      color: #ccc;
      background-color: transparent;
  }
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.navbar-inverse .navbar-brand {
  color: #777;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-text {
  color: #777;
}

.navbar-inverse .navbar-nav>li>a {
  color: #777;
}

.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:hover,
.navbar-inverse .navbar-nav>.disabled>a:focus {
  color: #444;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
  color: #fff;
  background-color: #080808;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
      border-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
      color: #777;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus {
      color: #fff;
      background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus {
      color: #fff;
      background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus {
      color: #444;
      background-color: transparent;
  }
}

.navbar-inverse .navbar-link {
  color: #777;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #777;
}

.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.breadcrumb>.active {
  color: #777;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #428bca;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
  color: #2a6496;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #428bca;
  border-color: #428bca;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.pager li>a:hover,
.pager li>a:focus {
  text-decoration: none;
  background-color: #eee;
}

.pager .next>a,
.pager .next>span {
  float: right;
}

.pager .previous>a,
.pager .previous>span {
  float: left;
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #428bca;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #3071a9;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #777;
  border-radius: 10px;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge {
  top: 0;
  padding: 1px 5px;
}

a.badge:hover,
a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

a.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
  color: #428bca;
  background-color: #fff;
}

.nav-pills>li>a>.badge {
  margin-left: 3px;
}

.jumbotron {
  padding: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
}

.jumbotron h1,
.jumbotron .h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.jumbotron>hr {
  border-top-color: #d5d5d5;
}

.container .jumbotron {
  border-radius: 6px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
      padding-top: 48px;
      padding-bottom: 48px;
  }
  .container .jumbotron {
      padding-right: 60px;
      padding-left: 60px;
  }
  .jumbotron h1,
  .jumbotron .h1 {
      font-size: 63px;
  }
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.thumbnail>img,
.thumbnail a>img {
  margin-right: auto;
  margin-left: auto;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #428bca;
}

.thumbnail .caption {
  padding: 9px;
  color: #333;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert>p,
.alert>ul {
  margin-bottom: 0;
}

.alert>p+p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@-webkit-keyframes progress-bar-stripes {
  from {
      background-position: 40px 0;
  }
  to {
      background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
      background-position: 40px 0;
  }
  to {
      background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
      background-position: 40px 0;
  }
  to {
      background-position: 0 0;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar[aria-valuenow="1"],
.progress-bar[aria-valuenow="2"] {
  min-width: 30px;
}

.progress-bar[aria-valuenow="0"] {
  min-width: 30px;
  color: #777;
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media,
.media .media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media-object {
  display: block;
}

.media-heading {
  margin: 0 0 5px;
}

.media>.pull-left {
  margin-right: 10px;
}

.media>.pull-right {
  margin-left: 10px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group-item>.badge {
  float: right;
}

.list-group-item>.badge+.badge {
  margin-right: 5px;
}

a.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover,
a.list-group-item:focus {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  color: #777;
  background-color: #eee;
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #777;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>.small {
  color: inherit;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #e1edf7;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover,
a.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active,
a.list-group-item-success.active:hover,
a.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover,
a.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active,
a.list-group-item-info.active:hover,
a.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover,
a.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover,
a.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-heading>.dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel-title>a {
  color: inherit;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.list-group {
  margin-bottom: 0;
}

.panel>.list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel>.list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel>.list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel-heading+.list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group+.panel-footer {
  border-top-width: 0;
}

.panel>.table,
.panel>.table-responsive>.table,
.panel>.panel-collapse>.table {
  margin-bottom: 0;
}

.panel>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive {
  border-top: 1px solid #ddd;
}

.panel>.table>tbody:first-child>tr:first-child th,
.panel>.table>tbody:first-child>tr:first-child td {
  border-top: 0;
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
  border: 0;
}

.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
  border-left: 0;
}

.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
  border-right: 0;
}

.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
  border-bottom: 0;
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
  border-bottom: 0;
}

.panel>.table-responsive {
  margin-bottom: 0;
  border: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.panel-group .panel+.panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer+.panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default>.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd;
}

.panel-default>.panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #428bca;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #428bca;
}

.panel-primary>.panel-heading .badge {
  color: #428bca;
  background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #428bca;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success>.panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #d6e9c6;
}

.panel-success>.panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info>.panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #bce8f1;
}

.panel-info>.panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning>.panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #faebcc;
}

.panel-warning>.panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger>.panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ebccd1;
}

.panel-danger>.panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, .15);
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
  -webkit-transform: translate3d(0, -25%, 0);
  -o-transform: translate3d(0, -25%, 0);
  transform: translate3d(0, -25%, 0);
}

.modal.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: .5;
}

.modal-header {
  min-height: 16.42857143px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
      width: 600px;
      margin: 30px auto;
  }
  .modal-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }
  .modal-sm {
      width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
      width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  visibility: visible;
  filter: alpha(opacity=0);
  opacity: 0;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
  right: 5px;
  bottom: 0;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover>.arrow,
.popover>.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover>.arrow {
  border-width: 11px;
}

.popover>.arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top>.arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, .25);
  border-bottom-width: 0;
}

.popover.top>.arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}

.popover.right>.arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, .25);
  border-left-width: 0;
}

.popover.right>.arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}

.popover.bottom>.arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, .25);
}

.popover.bottom>.arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover.left>.arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, .25);
}

.popover.left>.arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner>.item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
  -o-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  line-height: 1;
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
  display: block;
}

.carousel-inner>.active {
  left: 0;
}

.carousel-inner>.next,
.carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner>.next {
  left: 100%;
}

.carousel-inner>.prev {
  left: -100%;
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
  left: 0;
}

.carousel-inner>.active.left {
  left: -100%;
}

.carousel-inner>.active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  filter: alpha(opacity=50);
  opacity: .5;
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
  background-image: -o-linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
  background-image: -o-linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: .9;
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-family: serif;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
      width: 30px;
      height: 30px;
      margin-top: -15px;
      font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
      margin-left: -15px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
      margin-right: -15px;
  }
  .carousel-caption {
      right: 20%;
      left: 20%;
      padding-bottom: 30px;
  }
  .carousel-indicators {
      bottom: 20px;
  }
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:before,
.btn-group-vertical>.btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none;
}

.affix {
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
      display: block !important;
  }
  table.visible-xs {
      display: table;
  }
  tr.visible-xs {
      display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
      display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
      display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
      display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
      display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
      display: block !important;
  }
  table.visible-sm {
      display: table;
  }
  tr.visible-sm {
      display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
      display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
      display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
      display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
      display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
      display: block !important;
  }
  table.visible-md {
      display: table;
  }
  tr.visible-md {
      display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
      display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
      display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
      display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
      display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
      display: block !important;
  }
  table.visible-lg {
      display: table;
  }
  tr.visible-lg {
      display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
      display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
      display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
      display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
      display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
      display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
      display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
      display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
      display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
      display: block !important;
  }
  table.visible-print {
      display: table;
  }
  tr.visible-print {
      display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
      display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
      display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
      display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
      display: inline-block !important;
  }
}

@media print {
  .hidden-print {
      display: none !important;
  }
}

@charset "UTF-8";
body {
  -webkit-backface-visibility: hidden;
}

.animated {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  -o-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes flash {
  0%,
  50%,
  100% {
      opacity: 1;
  }
  25%,
  75% {
      opacity: 0;
  }
}

@-moz-keyframes flash {
  0%,
  50%,
  100% {
      opacity: 1;
  }
  25%,
  75% {
      opacity: 0;
  }
}

@-o-keyframes flash {
  0%,
  50%,
  100% {
      opacity: 1;
  }
  25%,
  75% {
      opacity: 0;
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
      opacity: 1;
  }
  25%,
  75% {
      opacity: 0;
  }
}

.animated.flash {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes shake {
  0%,
  100% {
      -webkit-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      -webkit-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
      -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {
  0%,
  100% {
      -moz-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      -moz-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
      -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {
  0%,
  100% {
      -o-transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      -o-transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
      -o-transform: translateX(10px);
  }
}

@keyframes shake {
  0%,
  100% {
      transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
      transform: translateX(10px);
  }
}

.animated.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
      -webkit-transform: translateY(0);
  }
  40% {
      -webkit-transform: translateY(-30px);
  }
  60% {
      -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
      -moz-transform: translateY(0);
  }
  40% {
      -moz-transform: translateY(-30px);
  }
  60% {
      -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
      -o-transform: translateY(0);
  }
  40% {
      -o-transform: translateY(-30px);
  }
  60% {
      -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

.animated.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
      -webkit-transform: scale(1);
  }
  10%,
  20% {
      -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
      -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
      -webkit-transform: scale(1) rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
      -moz-transform: scale(1);
  }
  10%,
  20% {
      -moz-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
      -moz-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
      -moz-transform: scale(1.1) rotate(-3deg);
  }
  100% {
      -moz-transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
      -o-transform: scale(1);
  }
  10%,
  20% {
      -o-transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
      -o-transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
      -o-transform: scale(1.1) rotate(-3deg);
  }
  100% {
      -o-transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
      transform: scale(1);
  }
  10%,
  20% {
      transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
      transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
      transform: scale(1.1) rotate(-3deg);
  }
  100% {
      transform: scale(1) rotate(0);
  }
}

.animated.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
      -webkit-transform-origin: top center;
  }
  20% {
      -webkit-transform: rotate(15deg);
  }
  40% {
      -webkit-transform: rotate(-10deg);
  }
  60% {
      -webkit-transform: rotate(5deg);
  }
  80% {
      -webkit-transform: rotate(-5deg);
  }
  100% {
      -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes swing {
  20% {
      -moz-transform: rotate(15deg);
  }
  40% {
      -moz-transform: rotate(-10deg);
  }
  60% {
      -moz-transform: rotate(5deg);
  }
  80% {
      -moz-transform: rotate(-5deg);
  }
  100% {
      -moz-transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20% {
      -o-transform: rotate(15deg);
  }
  40% {
      -o-transform: rotate(-10deg);
  }
  60% {
      -o-transform: rotate(5deg);
  }
  80% {
      -o-transform: rotate(-5deg);
  }
  100% {
      -o-transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
      transform: rotate(15deg);
  }
  40% {
      transform: rotate(-10deg);
  }
  60% {
      transform: rotate(5deg);
  }
  80% {
      transform: rotate(-5deg);
  }
  100% {
      transform: rotate(0deg);
  }
}

.animated.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
      -webkit-transform: translateX(0%);
  }
  15% {
      -webkit-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
      -webkit-transform: translateX(20%) rotate(3deg);
  }
  45% {
      -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
      -webkit-transform: translateX(10%) rotate(2deg);
  }
  75% {
      -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
      -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
      -moz-transform: translateX(0%);
  }
  15% {
      -moz-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
      -moz-transform: translateX(20%) rotate(3deg);
  }
  45% {
      -moz-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
      -moz-transform: translateX(10%) rotate(2deg);
  }
  75% {
      -moz-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
      -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
      -o-transform: translateX(0%);
  }
  15% {
      -o-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
      -o-transform: translateX(20%) rotate(3deg);
  }
  45% {
      -o-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
      -o-transform: translateX(10%) rotate(2deg);
  }
  75% {
      -o-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
      -o-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
      transform: translateX(0%);
  }
  15% {
      transform: translateX(-25%) rotate(-5deg);
  }
  30% {
      transform: translateX(20%) rotate(3deg);
  }
  45% {
      transform: translateX(-15%) rotate(-3deg);
  }
  60% {
      transform: translateX(10%) rotate(2deg);
  }
  75% {
      transform: translateX(-5%) rotate(-1deg);
  }
  100% {
      transform: translateX(0%);
  }
}

.animated.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes pulse {
  0% {
      -webkit-transform: scale(1);
  }
  50% {
      -webkit-transform: scale(1.1);
  }
  100% {
      -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
      -moz-transform: scale(1);
  }
  50% {
      -moz-transform: scale(1.1);
  }
  100% {
      -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
      -o-transform: scale(1);
  }
  50% {
      -o-transform: scale(1.1);
  }
  100% {
      -o-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}

.animated.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -webkit-animation-timing-function: ease-out;
  }
  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
      -webkit-animation-timing-function: ease-out;
  }
  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
      -webkit-animation-timing-function: ease-in;
  }
  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
      -webkit-animation-timing-function: ease-in;
  }
  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
      -webkit-animation-timing-function: ease-in;
  }
}

@-moz-keyframes flip {
  0% {
      -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -moz-animation-timing-function: ease-out;
  }
  40% {
      -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
      -moz-animation-timing-function: ease-out;
  }
  50% {
      -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
      -moz-animation-timing-function: ease-in;
  }
  80% {
      -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
      -moz-animation-timing-function: ease-in;
  }
  100% {
      -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
      -moz-animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
      -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -o-animation-timing-function: ease-out;
  }
  40% {
      -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
      -o-animation-timing-function: ease-out;
  }
  50% {
      -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
      -o-animation-timing-function: ease-in;
  }
  80% {
      -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
      -o-animation-timing-function: ease-in;
  }
  100% {
      -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
      -o-animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
      transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      animation-timing-function: ease-out;
  }
  40% {
      transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
      animation-timing-function: ease-out;
  }
  50% {
      transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
      animation-timing-function: ease-in;
  }
  80% {
      transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
      animation-timing-function: ease-in;
  }
  100% {
      transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
      animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
  40% {
      -webkit-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
      -webkit-transform: perspective(400px) rotateX(10deg);
  }
  100% {
      -webkit-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
}

@-moz-keyframes flipInX {
  0% {
      -moz-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
  40% {
      -moz-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
      -moz-transform: perspective(400px) rotateX(10deg);
  }
  100% {
      -moz-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
}

@-o-keyframes flipInX {
  0% {
      -o-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
  40% {
      -o-transform: perspective(400px) rotateX(-10deg);
  }
  70% {
      -o-transform: perspective(400px) rotateX(10deg);
  }
  100% {
      -o-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
}

@keyframes flipInX {
  0% {
      transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
  40% {
      transform: perspective(400px) rotateX(-10deg);
  }
  70% {
      transform: perspective(400px) rotateX(10deg);
  }
  100% {
      transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
}

.animated.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  0% {
      -webkit-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
  100% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
}

@-moz-keyframes flipOutX {
  0% {
      -moz-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
  100% {
      -moz-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
}

@-o-keyframes flipOutX {
  0% {
      -o-transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
  100% {
      -o-transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
      transform: perspective(400px) rotateX(0deg);
      opacity: 1;
  }
  100% {
      transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
}

.animated.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
  40% {
      -webkit-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
      -webkit-transform: perspective(400px) rotateY(10deg);
  }
  100% {
      -webkit-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
}

@-moz-keyframes flipInY {
  0% {
      -moz-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
  40% {
      -moz-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
      -moz-transform: perspective(400px) rotateY(10deg);
  }
  100% {
      -moz-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
}

@-o-keyframes flipInY {
  0% {
      -o-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
  40% {
      -o-transform: perspective(400px) rotateY(-10deg);
  }
  70% {
      -o-transform: perspective(400px) rotateY(10deg);
  }
  100% {
      -o-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
}

@keyframes flipInY {
  0% {
      transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
  40% {
      transform: perspective(400px) rotateY(-10deg);
  }
  70% {
      transform: perspective(400px) rotateY(10deg);
  }
  100% {
      transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
}

.animated.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
      -webkit-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
  100% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
}

@-moz-keyframes flipOutY {
  0% {
      -moz-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
  100% {
      -moz-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
}

@-o-keyframes flipOutY {
  0% {
      -o-transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
  100% {
      -o-transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
      transform: perspective(400px) rotateY(0deg);
      opacity: 1;
  }
  100% {
      transform: perspective(400px) rotateY(90deg);
      opacity: 0;
  }
}

.animated.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@-webkit-keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.animated.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
      opacity: 0;
      -moz-transform: translateY(20px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
      opacity: 0;
      -o-transform: translateY(20px);
  }
  100% {
      opacity: 1;
      -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animated.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
      opacity: 0;
      -moz-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
      opacity: 0;
      -o-transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animated.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
      opacity: 0;
      -moz-transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
      opacity: 0;
      -o-transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
      opacity: 0;
      transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.animated.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
      opacity: 0;
      -moz-transform: translateX(20px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
      opacity: 0;
      -o-transform: translateX(20px);
  }
  100% {
      opacity: 1;
      -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      transform: translateX(20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.animated.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
      opacity: 0;
      -moz-transform: translateY(2000px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
      opacity: 0;
      -o-transform: translateY(2000px);
  }
  100% {
      opacity: 1;
      -o-transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
      opacity: 0;
      transform: translateY(2000px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animated.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
      opacity: 0;
      -moz-transform: translateY(-2000px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
      opacity: 0;
      -o-transform: translateY(-2000px);
  }
  100% {
      opacity: 1;
      -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
      opacity: 0;
      transform: translateY(-2000px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.animated.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
  }
  100% {
      opacity: 1;
      -o-transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      transform: translateX(-2000px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.animated.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
  }
  100% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
      opacity: 0;
      -o-transform: translateX(2000px);
  }
  100% {
      opacity: 1;
      -o-transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
      opacity: 0;
      transform: translateX(2000px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.animated.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.animated.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutUp {
  0% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
      opacity: 1;
      -o-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(-20px);
  }
}

.animated.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
      opacity: 1;
      -o-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(20px);
  }
}

.animated.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
      opacity: 1;
      -o-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
      opacity: 1;
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      transform: translateX(-20px);
  }
}

.animated.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
      opacity: 1;
      -o-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
      opacity: 1;
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      transform: translateX(20px);
  }
}

.animated.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      -o-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(-2000px);
  }
}

.animated.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      -moz-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      -o-transform: translateY(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(2000px);
  }
}

.animated.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      -o-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      transform: translateX(-2000px);
  }
}

.animated.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      -moz-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      -o-transform: translateX(0);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      transform: translateX(2000px);
  }
}

.animated.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes bounceIn {
  0% {
      opacity: 0;
      -webkit-transform: scale(.3);
  }
  50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
  }
  70% {
      -webkit-transform: scale(.9);
  }
  100% {
      -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
      opacity: 0;
      -moz-transform: scale(.3);
  }
  50% {
      opacity: 1;
      -moz-transform: scale(1.05);
  }
  70% {
      -moz-transform: scale(.9);
  }
  100% {
      -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
      opacity: 0;
      -o-transform: scale(.3);
  }
  50% {
      opacity: 1;
      -o-transform: scale(1.05);
  }
  70% {
      -o-transform: scale(.9);
  }
  100% {
      -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
      opacity: 0;
      transform: scale(.3);
  }
  50% {
      opacity: 1;
      transform: scale(1.05);
  }
  70% {
      transform: scale(.9);
  }
  100% {
      transform: scale(1);
  }
}

.animated.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
  }
  80% {
      -webkit-transform: translateY(10px);
  }
  100% {
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
      opacity: 0;
      -moz-transform: translateY(2000px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateY(-30px);
  }
  80% {
      -moz-transform: translateY(10px);
  }
  100% {
      -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
      opacity: 0;
      -o-transform: translateY(2000px);
  }
  60% {
      opacity: 1;
      -o-transform: translateY(-30px);
  }
  80% {
      -o-transform: translateY(10px);
  }
  100% {
      -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
      opacity: 0;
      transform: translateY(2000px);
  }
  60% {
      opacity: 1;
      transform: translateY(-30px);
  }
  80% {
      transform: translateY(10px);
  }
  100% {
      transform: translateY(0);
  }
}

.animated.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateY(30px);
  }
  80% {
      -webkit-transform: translateY(-10px);
  }
  100% {
      -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
      opacity: 0;
      -moz-transform: translateY(-2000px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateY(30px);
  }
  80% {
      -moz-transform: translateY(-10px);
  }
  100% {
      -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
      opacity: 0;
      -o-transform: translateY(-2000px);
  }
  60% {
      opacity: 1;
      -o-transform: translateY(30px);
  }
  80% {
      -o-transform: translateY(-10px);
  }
  100% {
      -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
      opacity: 0;
      transform: translateY(-2000px);
  }
  60% {
      opacity: 1;
      transform: translateY(30px);
  }
  80% {
      transform: translateY(-10px);
  }
  100% {
      transform: translateY(0);
  }
}

.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(30px);
  }
  80% {
      -webkit-transform: translateX(-10px);
  }
  100% {
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateX(30px);
  }
  80% {
      -moz-transform: translateX(-10px);
  }
  100% {
      -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
  }
  60% {
      opacity: 1;
      -o-transform: translateX(30px);
  }
  80% {
      -o-transform: translateX(-10px);
  }
  100% {
      -o-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
      opacity: 0;
      transform: translateX(-2000px);
  }
  60% {
      opacity: 1;
      transform: translateX(30px);
  }
  80% {
      transform: translateX(-10px);
  }
  100% {
      transform: translateX(0);
  }
}

.animated.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
  }
  60% {
      opacity: 1;
      -webkit-transform: translateX(-30px);
  }
  80% {
      -webkit-transform: translateX(10px);
  }
  100% {
      -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
  }
  60% {
      opacity: 1;
      -moz-transform: translateX(-30px);
  }
  80% {
      -moz-transform: translateX(10px);
  }
  100% {
      -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
      opacity: 0;
      -o-transform: translateX(2000px);
  }
  60% {
      opacity: 1;
      -o-transform: translateX(-30px);
  }
  80% {
      -o-transform: translateX(10px);
  }
  100% {
      -o-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
      opacity: 0;
      transform: translateX(2000px);
  }
  60% {
      opacity: 1;
      transform: translateX(-30px);
  }
  80% {
      transform: translateX(10px);
  }
  100% {
      transform: translateX(0);
  }
}

.animated.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
      -webkit-transform: scale(1);
  }
  25% {
      -webkit-transform: scale(.95);
  }
  50% {
      opacity: 1;
      -webkit-transform: scale(1.1);
  }
  100% {
      opacity: 0;
      -webkit-transform: scale(.3);
  }
}

@-moz-keyframes bounceOut {
  0% {
      -moz-transform: scale(1);
  }
  25% {
      -moz-transform: scale(.95);
  }
  50% {
      opacity: 1;
      -moz-transform: scale(1.1);
  }
  100% {
      opacity: 0;
      -moz-transform: scale(.3);
  }
}

@-o-keyframes bounceOut {
  0% {
      -o-transform: scale(1);
  }
  25% {
      -o-transform: scale(.95);
  }
  50% {
      opacity: 1;
      -o-transform: scale(1.1);
  }
  100% {
      opacity: 0;
      -o-transform: scale(.3);
  }
}

@keyframes bounceOut {
  0% {
      transform: scale(1);
  }
  25% {
      transform: scale(.95);
  }
  50% {
      opacity: 1;
      transform: scale(1.1);
  }
  100% {
      opacity: 0;
      transform: scale(.3);
  }
}

.animated.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
      -webkit-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -webkit-transform: translateY(20px);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
      -moz-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -moz-transform: translateY(20px);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
      -o-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -o-transform: translateY(20px);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
      transform: translateY(0);
  }
  20% {
      opacity: 1;
      transform: translateY(20px);
  }
  100% {
      opacity: 0;
      transform: translateY(-2000px);
  }
}

.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
      -webkit-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -webkit-transform: translateY(-20px);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
      -moz-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -moz-transform: translateY(-20px);
  }
  100% {
      opacity: 0;
      -moz-transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
      -o-transform: translateY(0);
  }
  20% {
      opacity: 1;
      -o-transform: translateY(-20px);
  }
  100% {
      opacity: 0;
      -o-transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
      transform: translateY(0);
  }
  20% {
      opacity: 1;
      transform: translateY(-20px);
  }
  100% {
      opacity: 0;
      transform: translateY(2000px);
  }
}

.animated.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
      -webkit-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -webkit-transform: translateX(20px);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
      -moz-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -moz-transform: translateX(20px);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
      -o-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -o-transform: translateX(20px);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
      transform: translateX(0);
  }
  20% {
      opacity: 1;
      transform: translateX(20px);
  }
  100% {
      opacity: 0;
      transform: translateX(-2000px);
  }
}

.animated.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
      -webkit-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
      -moz-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -moz-transform: translateX(-20px);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
      -o-transform: translateX(0);
  }
  20% {
      opacity: 1;
      -o-transform: translateX(-20px);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
      transform: translateX(0);
  }
  20% {
      opacity: 1;
      transform: translateX(-20px);
  }
  100% {
      opacity: 0;
      transform: translateX(2000px);
  }
}

.animated.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}

@-moz-keyframes rotateIn {
  0% {
      -moz-transform-origin: center center;
      -moz-transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      -moz-transform-origin: center center;
      -moz-transform: rotate(0);
      opacity: 1;
  }
}

@-o-keyframes rotateIn {
  0% {
      -o-transform-origin: center center;
      -o-transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      -o-transform-origin: center center;
      -o-transform: rotate(0);
      opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
      transform-origin: center center;
      transform: rotate(-200deg);
      opacity: 0;
  }
  100% {
      transform-origin: center center;
      transform: rotate(0);
      opacity: 1;
  }
}

.animated.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
      transform-origin: left bottom;
      transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
  }
}

.animated.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
      transform-origin: left bottom;
      transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
  }
}

.animated.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
}

@-o-keyframes rotateInUpRight {
  0% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
      transform-origin: right bottom;
      transform: rotate(-90deg);
      opacity: 0;
  }
  100% {
      transform-origin: right bottom;
      transform: rotate(0);
      opacity: 1;
  }
}

.animated.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
}

@-o-keyframes rotateInDownRight {
  0% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
      transform-origin: right bottom;
      transform: rotate(90deg);
      opacity: 0;
  }
  100% {
      transform-origin: right bottom;
      transform: rotate(0);
      opacity: 1;
  }
}

.animated.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(200deg);
      opacity: 0;
  }
}

@-moz-keyframes rotateOut {
  0% {
      -moz-transform-origin: center center;
      -moz-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -moz-transform-origin: center center;
      -moz-transform: rotate(200deg);
      opacity: 0;
  }
}

@-o-keyframes rotateOut {
  0% {
      -o-transform-origin: center center;
      -o-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -o-transform-origin: center center;
      -o-transform: rotate(200deg);
      opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
      transform-origin: center center;
      transform: rotate(0);
      opacity: 1;
  }
  100% {
      transform-origin: center center;
      transform: rotate(200deg);
      opacity: 0;
  }
}

.animated.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      opacity: 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(-90deg);
      opacity: 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(-90deg);
      opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
  }
  100% {
      transform-origin: left bottom;
      transform: rotate(-90deg);
      opacity: 0;
  }
}

.animated.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      opacity: 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -moz-transform-origin: left bottom;
      -moz-transform: rotate(90deg);
      opacity: 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -o-transform-origin: left bottom;
      -o-transform: rotate(90deg);
      opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
      transform-origin: left bottom;
      transform: rotate(0);
      opacity: 1;
  }
  100% {
      transform-origin: left bottom;
      transform: rotate(90deg);
      opacity: 0;
  }
}

.animated.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      opacity: 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(90deg);
      opacity: 0;
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(90deg);
      opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
      transform-origin: right bottom;
      transform: rotate(0);
      opacity: 1;
  }
  100% {
      transform-origin: right bottom;
      transform: rotate(90deg);
      opacity: 0;
  }
}

.animated.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -webkit-transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      opacity: 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -moz-transform-origin: right bottom;
      -moz-transform: rotate(-90deg);
      opacity: 0;
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(0);
      opacity: 1;
  }
  100% {
      -o-transform-origin: right bottom;
      -o-transform: rotate(-90deg);
      opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
      transform-origin: right bottom;
      transform: rotate(0);
      opacity: 1;
  }
  100% {
      transform-origin: right bottom;
      transform: rotate(-90deg);
      opacity: 0;
  }
}

.animated.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes hinge {
  0% {
      -webkit-transform: rotate(0);
      -webkit-transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
      -webkit-transform: rotate(80deg);
      -webkit-transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
  }
  40% {
      -webkit-transform: rotate(60deg);
      -webkit-transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
  }
  80% {
      -webkit-transform: rotate(60deg) translateY(0);
      opacity: 1;
      -webkit-transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
  }
  100% {
      -webkit-transform: translateY(700px);
      opacity: 0;
  }
}

@-moz-keyframes hinge {
  0% {
      -moz-transform: rotate(0);
      -moz-transform-origin: top left;
      -moz-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
      -moz-transform: rotate(80deg);
      -moz-transform-origin: top left;
      -moz-animation-timing-function: ease-in-out;
  }
  40% {
      -moz-transform: rotate(60deg);
      -moz-transform-origin: top left;
      -moz-animation-timing-function: ease-in-out;
  }
  80% {
      -moz-transform: rotate(60deg) translateY(0);
      opacity: 1;
      -moz-transform-origin: top left;
      -moz-animation-timing-function: ease-in-out;
  }
  100% {
      -moz-transform: translateY(700px);
      opacity: 0;
  }
}

@-o-keyframes hinge {
  0% {
      -o-transform: rotate(0);
      -o-transform-origin: top left;
      -o-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
      -o-transform: rotate(80deg);
      -o-transform-origin: top left;
      -o-animation-timing-function: ease-in-out;
  }
  40% {
      -o-transform: rotate(60deg);
      -o-transform-origin: top left;
      -o-animation-timing-function: ease-in-out;
  }
  80% {
      -o-transform: rotate(60deg) translateY(0);
      opacity: 1;
      -o-transform-origin: top left;
      -o-animation-timing-function: ease-in-out;
  }
  100% {
      -o-transform: translateY(700px);
      opacity: 0;
  }
}

@keyframes hinge {
  0% {
      transform: rotate(0);
      transform-origin: top left;
      animation-timing-function: ease-in-out;
  }
  20%,
  60% {
      transform: rotate(80deg);
      transform-origin: top left;
      animation-timing-function: ease-in-out;
  }
  40% {
      transform: rotate(60deg);
      transform-origin: top left;
      animation-timing-function: ease-in-out;
  }
  80% {
      transform: rotate(60deg) translateY(0);
      opacity: 1;
      transform-origin: top left;
      animation-timing-function: ease-in-out;
  }
  100% {
      transform: translateY(700px);
      opacity: 0;
  }
}

.animated.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0px) rotate(0deg);
  }
}

@-moz-keyframes rollIn {
  0% {
      opacity: 0;
      -moz-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
      opacity: 1;
      -moz-transform: translateX(0px) rotate(0deg);
  }
}

@-o-keyframes rollIn {
  0% {
      opacity: 0;
      -o-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
      opacity: 1;
      -o-transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
      opacity: 0;
      transform: translateX(-100%) rotate(-120deg);
  }
  100% {
      opacity: 1;
      transform: translateX(0px) rotate(0deg);
  }
}

.animated.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0px) rotate(0deg);
  }
  100% {
      opacity: 0;
      -webkit-transform: translateX(100%) rotate(120deg);
  }
}

@-moz-keyframes rollOut {
  0% {
      opacity: 1;
      -moz-transform: translateX(0px) rotate(0deg);
  }
  100% {
      opacity: 0;
      -moz-transform: translateX(100%) rotate(120deg);
  }
}

@-o-keyframes rollOut {
  0% {
      opacity: 1;
      -o-transform: translateX(0px) rotate(0deg);
  }
  100% {
      opacity: 0;
      -o-transform: translateX(100%) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
      opacity: 1;
      transform: translateX(0px) rotate(0deg);
  }
  100% {
      opacity: 0;
      transform: translateX(100%) rotate(120deg);
  }
}

.animated.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
  60% {
      -webkit-transform: translateX(-20%) skewX(30deg);
      opacity: 1;
  }
  80% {
      -webkit-transform: translateX(0%) skewX(-15deg);
      opacity: 1;
  }
  100% {
      -webkit-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
      -moz-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
  60% {
      -moz-transform: translateX(-20%) skewX(30deg);
      opacity: 1;
  }
  80% {
      -moz-transform: translateX(0%) skewX(-15deg);
      opacity: 1;
  }
  100% {
      -moz-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
}

@-o-keyframes lightSpeedIn {
  0% {
      -o-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
  60% {
      -o-transform: translateX(-20%) skewX(30deg);
      opacity: 1;
  }
  80% {
      -o-transform: translateX(0%) skewX(-15deg);
      opacity: 1;
  }
  100% {
      -o-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
      transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
  60% {
      transform: translateX(-20%) skewX(30deg);
      opacity: 1;
  }
  80% {
      transform: translateX(0%) skewX(-15deg);
      opacity: 1;
  }
  100% {
      transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
}

.animated.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.animated.lightSpeedIn {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes lightSpeedOut {
  0% {
      -webkit-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
  100% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
      -moz-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
  100% {
      -moz-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
}

@-o-keyframes lightSpeedOut {
  0% {
      -o-transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
  100% {
      -o-transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
      transform: translateX(0%) skewX(0deg);
      opacity: 1;
  }
  100% {
      transform: translateX(100%) skewX(-30deg);
      opacity: 0;
  }
}

.animated.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.animated.lightSpeedOut {
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfontba72.eot?v=4.0.3');
  src: url('fonts/fontawesome-webfontd41d.eot?#iefix&v=4.0.3') format('embedded-opentype'), url('fonts/fontawesome-webfontba72.woff?v=4.0.3') format('woff'), url('fonts/fontawesome-webfontba72.ttf?v=4.0.3') format('truetype'), url('fonts/fontawesome-webfontba72.svg?v=4.0.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal
}

.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.fa-lg {
  font-size: 1.3333333333333333em;
  line-height: .75em;
  vertical-align: -15%
}

.fa-2x {
  font-size: 2em
}

.fa-3x {
  font-size: 3em
}

.fa-4x {
  font-size: 4em
}

.fa-5x {
  font-size: 5em
}

.fa-fw {
  width: 1.2857142857142858em;
  text-align: center
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.142857142857143em;
  list-style-type: none
}

.fa-ul>li {
  position: relative
}

.fa-li {
  position: absolute;
  left: -2.142857142857143em;
  width: 2.142857142857143em;
  top: .14285714285714285em;
  text-align: center
}

.fa-li.fa-lg {
  left: -1.8571428571428572em
}

.fa-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em
}

.pull-right {
  float: right
}

.pull-left {
  float: left
}

.fa.pull-left {
  margin-right: .3em
}

.fa.pull-right {
  margin-left: .3em
}

.fa-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear
}

@-moz-keyframes spin {
  0% {
      -moz-transform: rotate(0deg)
  }
  100% {
      -moz-transform: rotate(359deg)
  }
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg)
  }
  100% {
      -webkit-transform: rotate(359deg)
  }
}

@-o-keyframes spin {
  0% {
      -o-transform: rotate(0deg)
  }
  100% {
      -o-transform: rotate(359deg)
  }
}

@-ms-keyframes spin {
  0% {
      -ms-transform: rotate(0deg)
  }
  100% {
      -ms-transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg)
  }
  100% {
      transform: rotate(359deg)
  }
}

.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg)
}

.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg)
}

.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg)
}

.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1)
}

.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1)
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center
}

.fa-stack-1x {
  line-height: inherit
}

.fa-stack-2x {
  font-size: 2em
}

.fa-inverse {
  color: #fff
}

.fa-glass:before {
  content: "\f000"
}

.fa-music:before {
  content: "\f001"
}

.fa-search:before {
  content: "\f002"
}

.fa-envelope-o:before {
  content: "\f003"
}

.fa-heart:before {
  content: "\f004"
}

.fa-star:before {
  content: "\f005"
}

.fa-star-o:before {
  content: "\f006"
}

.fa-user:before {
  content: "\f007"
}

.fa-film:before {
  content: "\f008"
}

.fa-th-large:before {
  content: "\f009"
}

.fa-th:before {
  content: "\f00a"
}

.fa-th-list:before {
  content: "\f00b"
}

.fa-check:before {
  content: "\f00c"
}

.fa-times:before {
  content: "\f00d"
}

.fa-search-plus:before {
  content: "\f00e"
}

.fa-search-minus:before {
  content: "\f010"
}

.fa-power-off:before {
  content: "\f011"
}

.fa-signal:before {
  content: "\f012"
}

.fa-gear:before,
.fa-cog:before {
  content: "\f013"
}

.fa-trash-o:before {
  content: "\f014"
}

.fa-home:before {
  content: "\f015"
}

.fa-file-o:before {
  content: "\f016"
}

.fa-clock-o:before {
  content: "\f017"
}

.fa-road:before {
  content: "\f018"
}

.fa-download:before {
  content: "\f019"
}

.fa-arrow-circle-o-down:before {
  content: "\f01a"
}

.fa-arrow-circle-o-up:before {
  content: "\f01b"
}

.fa-inbox:before {
  content: "\f01c"
}

.fa-play-circle-o:before {
  content: "\f01d"
}

.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e"
}

.fa-refresh:before {
  content: "\f021"
}

.fa-list-alt:before {
  content: "\f022"
}

.fa-lock:before {
  content: "\f023"
}

.fa-flag:before {
  content: "\f024"
}

.fa-headphones:before {
  content: "\f025"
}

.fa-volume-off:before {
  content: "\f026"
}

.fa-volume-down:before {
  content: "\f027"
}

.fa-volume-up:before {
  content: "\f028"
}

.fa-qrcode:before {
  content: "\f029"
}

.fa-barcode:before {
  content: "\f02a"
}

.fa-tag:before {
  content: "\f02b"
}

.fa-tags:before {
  content: "\f02c"
}

.fa-book:before {
  content: "\f02d"
}

.fa-bookmark:before {
  content: "\f02e"
}

.fa-print:before {
  content: "\f02f"
}

.fa-camera:before {
  content: "\f030"
}

.fa-font:before {
  content: "\f031"
}

.fa-bold:before {
  content: "\f032"
}

.fa-italic:before {
  content: "\f033"
}

.fa-text-height:before {
  content: "\f034"
}

.fa-text-width:before {
  content: "\f035"
}

.fa-align-left:before {
  content: "\f036"
}

.fa-align-center:before {
  content: "\f037"
}

.fa-align-right:before {
  content: "\f038"
}

.fa-align-justify:before {
  content: "\f039"
}

.fa-list:before {
  content: "\f03a"
}

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b"
}

.fa-indent:before {
  content: "\f03c"
}

.fa-video-camera:before {
  content: "\f03d"
}

.fa-picture-o:before {
  content: "\f03e"
}

.fa-pencil:before {
  content: "\f040"
}

.fa-map-marker:before {
  content: "\f041"
}

.fa-adjust:before {
  content: "\f042"
}

.fa-tint:before {
  content: "\f043"
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044"
}

.fa-share-square-o:before {
  content: "\f045"
}

.fa-check-square-o:before {
  content: "\f046"
}

.fa-arrows:before {
  content: "\f047"
}

.fa-step-backward:before {
  content: "\f048"
}

.fa-fast-backward:before {
  content: "\f049"
}

.fa-backward:before {
  content: "\f04a"
}

.fa-play:before {
  content: "\f04b"
}

.fa-pause:before {
  content: "\f04c"
}

.fa-stop:before {
  content: "\f04d"
}

.fa-forward:before {
  content: "\f04e"
}

.fa-fast-forward:before {
  content: "\f050"
}

.fa-step-forward:before {
  content: "\f051"
}

.fa-eject:before {
  content: "\f052"
}

.fa-chevron-left:before {
  content: "\f053"
}

.fa-chevron-right:before {
  content: "\f054"
}

.fa-plus-circle:before {
  content: "\f055"
}

.fa-minus-circle:before {
  content: "\f056"
}

.fa-times-circle:before {
  content: "\f057"
}

.fa-check-circle:before {
  content: "\f058"
}

.fa-question-circle:before {
  content: "\f059"
}

.fa-info-circle:before {
  content: "\f05a"
}

.fa-crosshairs:before {
  content: "\f05b"
}

.fa-times-circle-o:before {
  content: "\f05c"
}

.fa-check-circle-o:before {
  content: "\f05d"
}

.fa-ban:before {
  content: "\f05e"
}

.fa-arrow-left:before {
  content: "\f060"
}

.fa-arrow-right:before {
  content: "\f061"
}

.fa-arrow-up:before {
  content: "\f062"
}

.fa-arrow-down:before {
  content: "\f063"
}

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064"
}

.fa-expand:before {
  content: "\f065"
}

.fa-compress:before {
  content: "\f066"
}

.fa-plus:before {
  content: "\f067"
}

.fa-minus:before {
  content: "\f068"
}

.fa-asterisk:before {
  content: "\f069"
}

.fa-exclamation-circle:before {
  content: "\f06a"
}

.fa-gift:before {
  content: "\f06b"
}

.fa-leaf:before {
  content: "\f06c"
}

.fa-fire:before {
  content: "\f06d"
}

.fa-eye:before {
  content: "\f06e"
}

.fa-eye-slash:before {
  content: "\f070"
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071"
}

.fa-plane:before {
  content: "\f072"
}

.fa-calendar:before {
  content: "\f073"
}

.fa-random:before {
  content: "\f074"
}

.fa-comment:before {
  content: "\f075"
}

.fa-magnet:before {
  content: "\f076"
}

.fa-chevron-up:before {
  content: "\f077"
}

.fa-chevron-down:before {
  content: "\f078"
}

.fa-retweet:before {
  content: "\f079"
}

.fa-shopping-cart:before {
  content: "\f07a"
}

.fa-folder:before {
  content: "\f07b"
}

.fa-folder-open:before {
  content: "\f07c"
}

.fa-arrows-v:before {
  content: "\f07d"
}

.fa-arrows-h:before {
  content: "\f07e"
}

.fa-bar-chart-o:before {
  content: "\f080"
}

.fa-twitter-square:before {
  content: "\f081"
}

.fa-facebook-square:before {
  content: "\f082"
}

.fa-camera-retro:before {
  content: "\f083"
}

.fa-key:before {
  content: "\f084"
}

.fa-gears:before,
.fa-cogs:before {
  content: "\f085"
}

.fa-comments:before {
  content: "\f086"
}

.fa-thumbs-o-up:before {
  content: "\f087"
}

.fa-thumbs-o-down:before {
  content: "\f088"
}

.fa-star-half:before {
  content: "\f089"
}

.fa-heart-o:before {
  content: "\f08a"
}

.fa-sign-out:before {
  content: "\f08b"
}

.fa-linkedin-square:before {
  content: "\f08c"
}

.fa-thumb-tack:before {
  content: "\f08d"
}

.fa-external-link:before {
  content: "\f08e"
}

.fa-sign-in:before {
  content: "\f090"
}

.fa-trophy:before {
  content: "\f091"
}

.fa-github-square:before {
  content: "\f092"
}

.fa-upload:before {
  content: "\f093"
}

.fa-lemon-o:before {
  content: "\f094"
}

.fa-phone:before {
  content: "\f095"
}

.fa-square-o:before {
  content: "\f096"
}

.fa-bookmark-o:before {
  content: "\f097"
}

.fa-phone-square:before {
  content: "\f098"
}

.fa-twitter:before {
  content: "\f099"
}

.fa-facebook:before {
  content: "\f09a"
}

.fa-github:before {
  content: "\f09b"
}

.fa-unlock:before {
  content: "\f09c"
}

.fa-credit-card:before {
  content: "\f09d"
}

.fa-rss:before {
  content: "\f09e"
}

.fa-hdd-o:before {
  content: "\f0a0"
}

.fa-bullhorn:before {
  content: "\f0a1"
}

.fa-bell:before {
  content: "\f0f3"
}

.fa-certificate:before {
  content: "\f0a3"
}

.fa-hand-o-right:before {
  content: "\f0a4"
}

.fa-hand-o-left:before {
  content: "\f0a5"
}

.fa-hand-o-up:before {
  content: "\f0a6"
}

.fa-hand-o-down:before {
  content: "\f0a7"
}

.fa-arrow-circle-left:before {
  content: "\f0a8"
}

.fa-arrow-circle-right:before {
  content: "\f0a9"
}

.fa-arrow-circle-up:before {
  content: "\f0aa"
}

.fa-arrow-circle-down:before {
  content: "\f0ab"
}

.fa-globe:before {
  content: "\f0ac"
}

.fa-wrench:before {
  content: "\f0ad"
}

.fa-tasks:before {
  content: "\f0ae"
}

.fa-filter:before {
  content: "\f0b0"
}

.fa-briefcase:before {
  content: "\f0b1"
}

.fa-arrows-alt:before {
  content: "\f0b2"
}

.fa-group:before,
.fa-users:before {
  content: "\f0c0"
}

.fa-chain:before,
.fa-link:before {
  content: "\f0c1"
}

.fa-cloud:before {
  content: "\f0c2"
}

.fa-flask:before {
  content: "\f0c3"
}

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4"
}

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5"
}

.fa-paperclip:before {
  content: "\f0c6"
}

.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7"
}

.fa-square:before {
  content: "\f0c8"
}

.fa-bars:before {
  content: "\f0c9"
}

.fa-list-ul:before {
  content: "\f0ca"
}

.fa-list-ol:before {
  content: "\f0cb"
}

.fa-strikethrough:before {
  content: "\f0cc"
}

.fa-underline:before {
  content: "\f0cd"
}

.fa-table:before {
  content: "\f0ce"
}

.fa-magic:before {
  content: "\f0d0"
}

.fa-truck:before {
  content: "\f0d1"
}

.fa-pinterest:before {
  content: "\f0d2"
}

.fa-pinterest-square:before {
  content: "\f0d3"
}

.fa-google-plus-square:before {
  content: "\f0d4"
}

.fa-google-plus:before {
  content: "\f0d5"
}

.fa-money:before {
  content: "\f0d6"
}

.fa-caret-down:before {
  content: "\f0d7"
}

.fa-caret-up:before {
  content: "\f0d8"
}

.fa-caret-left:before {
  content: "\f0d9"
}

.fa-caret-right:before {
  content: "\f0da"
}

.fa-columns:before {
  content: "\f0db"
}

.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc"
}

.fa-sort-down:before,
.fa-sort-asc:before {
  content: "\f0dd"
}

.fa-sort-up:before,
.fa-sort-desc:before {
  content: "\f0de"
}

.fa-envelope:before {
  content: "\f0e0"
}

.fa-linkedin:before {
  content: "\f0e1"
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2"
}

.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3"
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4"
}

.fa-comment-o:before {
  content: "\f0e5"
}

.fa-comments-o:before {
  content: "\f0e6"
}

.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7"
}

.fa-sitemap:before {
  content: "\f0e8"
}

.fa-umbrella:before {
  content: "\f0e9"
}

.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea"
}

.fa-lightbulb-o:before {
  content: "\f0eb"
}

.fa-exchange:before {
  content: "\f0ec"
}

.fa-cloud-download:before {
  content: "\f0ed"
}

.fa-cloud-upload:before {
  content: "\f0ee"
}

.fa-user-md:before {
  content: "\f0f0"
}

.fa-stethoscope:before {
  content: "\f0f1"
}

.fa-suitcase:before {
  content: "\f0f2"
}

.fa-bell-o:before {
  content: "\f0a2"
}

.fa-coffee:before {
  content: "\f0f4"
}

.fa-cutlery:before {
  content: "\f0f5"
}

.fa-file-text-o:before {
  content: "\f0f6"
}

.fa-building-o:before {
  content: "\f0f7"
}

.fa-hospital-o:before {
  content: "\f0f8"
}

.fa-ambulance:before {
  content: "\f0f9"
}

.fa-medkit:before {
  content: "\f0fa"
}

.fa-fighter-jet:before {
  content: "\f0fb"
}

.fa-beer:before {
  content: "\f0fc"
}

.fa-h-square:before {
  content: "\f0fd"
}

.fa-plus-square:before {
  content: "\f0fe"
}

.fa-angle-double-left:before {
  content: "\f100"
}

.fa-angle-double-right:before {
  content: "\f101"
}

.fa-angle-double-up:before {
  content: "\f102"
}

.fa-angle-double-down:before {
  content: "\f103"
}

.fa-angle-left:before {
  content: "\f104"
}

.fa-angle-right:before {
  content: "\f105"
}

.fa-angle-up:before {
  content: "\f106"
}

.fa-angle-down:before {
  content: "\f107"
}

.fa-desktop:before {
  content: "\f108"
}

.fa-laptop:before {
  content: "\f109"
}

.fa-tablet:before {
  content: "\f10a"
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b"
}

.fa-circle-o:before {
  content: "\f10c"
}

.fa-quote-left:before {
  content: "\f10d"
}

.fa-quote-right:before {
  content: "\f10e"
}

.fa-spinner:before {
  content: "\f110"
}

.fa-circle:before {
  content: "\f111"
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112"
}

.fa-github-alt:before {
  content: "\f113"
}

.fa-folder-o:before {
  content: "\f114"
}

.fa-folder-open-o:before {
  content: "\f115"
}

.fa-smile-o:before {
  content: "\f118"
}

.fa-frown-o:before {
  content: "\f119"
}

.fa-meh-o:before {
  content: "\f11a"
}

.fa-gamepad:before {
  content: "\f11b"
}

.fa-keyboard-o:before {
  content: "\f11c"
}

.fa-flag-o:before {
  content: "\f11d"
}

.fa-flag-checkered:before {
  content: "\f11e"
}

.fa-terminal:before {
  content: "\f120"
}

.fa-code:before {
  content: "\f121"
}

.fa-reply-all:before {
  content: "\f122"
}

.fa-mail-reply-all:before {
  content: "\f122"
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123"
}

.fa-location-arrow:before {
  content: "\f124"
}

.fa-crop:before {
  content: "\f125"
}

.fa-code-fork:before {
  content: "\f126"
}

.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127"
}

.fa-question:before {
  content: "\f128"
}

.fa-info:before {
  content: "\f129"
}

.fa-exclamation:before {
  content: "\f12a"
}

.fa-superscript:before {
  content: "\f12b"
}

.fa-subscript:before {
  content: "\f12c"
}

.fa-eraser:before {
  content: "\f12d"
}

.fa-puzzle-piece:before {
  content: "\f12e"
}

.fa-microphone:before {
  content: "\f130"
}

.fa-microphone-slash:before {
  content: "\f131"
}

.fa-shield:before {
  content: "\f132"
}

.fa-calendar-o:before {
  content: "\f133"
}

.fa-fire-extinguisher:before {
  content: "\f134"
}

.fa-rocket:before {
  content: "\f135"
}

.fa-maxcdn:before {
  content: "\f136"
}

.fa-chevron-circle-left:before {
  content: "\f137"
}

.fa-chevron-circle-right:before {
  content: "\f138"
}

.fa-chevron-circle-up:before {
  content: "\f139"
}

.fa-chevron-circle-down:before {
  content: "\f13a"
}

.fa-html5:before {
  content: "\f13b"
}

.fa-css3:before {
  content: "\f13c"
}

.fa-anchor:before {
  content: "\f13d"
}

.fa-unlock-alt:before {
  content: "\f13e"
}

.fa-bullseye:before {
  content: "\f140"
}

.fa-ellipsis-h:before {
  content: "\f141"
}

.fa-ellipsis-v:before {
  content: "\f142"
}

.fa-rss-square:before {
  content: "\f143"
}

.fa-play-circle:before {
  content: "\f144"
}

.fa-ticket:before {
  content: "\f145"
}

.fa-minus-square:before {
  content: "\f146"
}

.fa-minus-square-o:before {
  content: "\f147"
}

.fa-level-up:before {
  content: "\f148"
}

.fa-level-down:before {
  content: "\f149"
}

.fa-check-square:before {
  content: "\f14a"
}

.fa-pencil-square:before {
  content: "\f14b"
}

.fa-external-link-square:before {
  content: "\f14c"
}

.fa-share-square:before {
  content: "\f14d"
}

.fa-compass:before {
  content: "\f14e"
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150"
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151"
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152"
}

.fa-euro:before,
.fa-eur:before {
  content: "\f153"
}

.fa-gbp:before {
  content: "\f154"
}

.fa-dollar:before,
.fa-usd:before {
  content: "\f155"
}

.fa-rupee:before,
.fa-inr:before {
  content: "\f156"
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157"
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158"
}

.fa-won:before,
.fa-krw:before {
  content: "\f159"
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a"
}

.fa-file:before {
  content: "\f15b"
}

.fa-file-text:before {
  content: "\f15c"
}

.fa-sort-alpha-asc:before {
  content: "\f15d"
}

.fa-sort-alpha-desc:before {
  content: "\f15e"
}

.fa-sort-amount-asc:before {
  content: "\f160"
}

.fa-sort-amount-desc:before {
  content: "\f161"
}

.fa-sort-numeric-asc:before {
  content: "\f162"
}

.fa-sort-numeric-desc:before {
  content: "\f163"
}

.fa-thumbs-up:before {
  content: "\f164"
}

.fa-thumbs-down:before {
  content: "\f165"
}

.fa-youtube-square:before {
  content: "\f166"
}

.fa-youtube:before {
  content: "\f167"
}

.fa-xing:before {
  content: "\f168"
}

.fa-xing-square:before {
  content: "\f169"
}

.fa-youtube-play:before {
  content: "\f16a"
}

.fa-dropbox:before {
  content: "\f16b"
}

.fa-stack-overflow:before {
  content: "\f16c"
}

.fa-instagram:before {
  content: "\f16d"
}

.fa-flickr:before {
  content: "\f16e"
}

.fa-adn:before {
  content: "\f170"
}

.fa-bitbucket:before {
  content: "\f171"
}

.fa-bitbucket-square:before {
  content: "\f172"
}

.fa-tumblr:before {
  content: "\f173"
}

.fa-tumblr-square:before {
  content: "\f174"
}

.fa-long-arrow-down:before {
  content: "\f175"
}

.fa-long-arrow-up:before {
  content: "\f176"
}

.fa-long-arrow-left:before {
  content: "\f177"
}

.fa-long-arrow-right:before {
  content: "\f178"
}

.fa-apple:before {
  content: "\f179"
}

.fa-windows:before {
  content: "\f17a"
}

.fa-android:before {
  content: "\f17b"
}

.fa-linux:before {
  content: "\f17c"
}

.fa-dribbble:before {
  content: "\f17d"
}

.fa-skype:before {
  content: "\f17e"
}

.fa-foursquare:before {
  content: "\f180"
}

.fa-trello:before {
  content: "\f181"
}

.fa-female:before {
  content: "\f182"
}

.fa-male:before {
  content: "\f183"
}

.fa-gittip:before {
  content: "\f184"
}

.fa-sun-o:before {
  content: "\f185"
}

.fa-moon-o:before {
  content: "\f186"
}

.fa-archive:before {
  content: "\f187"
}

.fa-bug:before {
  content: "\f188"
}

.fa-vk:before {
  content: "\f189"
}

.fa-weibo:before {
  content: "\f18a"
}

.fa-renren:before {
  content: "\f18b"
}

.fa-pagelines:before {
  content: "\f18c"
}

.fa-stack-exchange:before {
  content: "\f18d"
}

.fa-arrow-circle-o-right:before {
  content: "\f18e"
}

.fa-arrow-circle-o-left:before {
  content: "\f190"
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191"
}

.fa-dot-circle-o:before {
  content: "\f192"
}

.fa-wheelchair:before {
  content: "\f193"
}

.fa-vimeo-square:before {
  content: "\f194"
}

.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195"
}

.fa-plus-square-o:before {
  content: "\f196"
}

.nav-docs>ul>li>a {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.dropfile {
  border: 2px dashed #e0e4e8;
  text-align: center;
  min-height: 20px;
}

.dropfile.hover {
  border-color: #aac3cc;
}

.dropfile small {
  margin: 50px 0;
  display: block;
}

.portlet {
  min-height: 30px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.axis {
  margin: 6px 0 0;
}

.axis li {
  vertical-align: top;
  font-size: 9px;
  margin-right: 2px;
  padding: 0;
  width: 10px;
  -webkit-text-size-adjust: none;
  text-align: center;
  line-height: 1em;
  word-wrap: nowrap;
}

.axis li:last-child {
  margin-right: 0 !important;
}

.axis-full {
  display: table;
}

.axis-full li {
  display: table-cell;
  padding: 0;
  width: 1%;
}

.easypiechart {
  position: relative;
  text-align: center;
}

.easypiechart .h2 {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}

.easypiechart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.easypiechart .easypie-text {
  position: absolute;
  z-index: 1;
  line-height: 1;
  font-size: 75%;
  width: 100%;
  top: 60%;
}

.easypiechart img {
  margin-top: -4px;
}

.combodate select {
  display: inline-block;
}

.doc-buttons .btn {
  margin-bottom: 5px;
}

.fontawesome-icon-list {
  list-style: none;
}

.fontawesome-icon-list i {
  font-size: 14px;
  width: 40px;
  margin: 0;
  display: inline-block;
  text-align: center;
}

.fontawesome-icon-list a {
  line-height: 32px;
  display: block;
  white-space: nowrap;
}

.fontawesome-icon-list a:hover i {
  font-size: 28px;
  vertical-align: middle;
}

.th-sortable {
  cursor: pointer;
}

.th-sortable .th-sort {
  float: right;
  position: relative;
}

.th-sort i {
  position: relative;
  z-index: 1;
}

.th-sort .fa-sort {
  position: absolute;
  left: 0;
  top: 3px;
  color: #bac3cc;
  z-index: 0;
}

.active>.th-sort .text {
  display: none;
}

.active>.th-sort .text-active {
  display: inline !important;
}

.sortable-placeholder {
  list-style: none;
  border: 1px dashed #CCC;
  min-height: 50px;
  margin-bottom: 5px;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.parsley-error-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 6px;
  font-size: 12px;
}

.parsley-error {
  border-color: #ff5f5f !important;
}

.graph {
  height: 250px;
}

.graph tspan {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.morris-hover {
  position: absolute;
  z-index: 1000;
}

.morris-hover.morris-default-style {
  border-radius: 2px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.9);
  border: solid 1px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.panel .fc-header {
  position: relative;
  height: 40px;
}

.panel .fc-header .fc-button {
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  height: 40px;
  line-height: 18px;
  padding: 10px;
}

.panel .fc-header .fc-button.fc-state-active {
  background: #fff;
}

.panel .fc-header .fc-header-title h2 {
  font-size: 15px;
  line-height: 40px;
  margin: 0;
}

.panel .fc-header td {
  position: absolute;
  white-space: nowrap;
}

.panel .fc-header .fc-header-left {
  width: 100%;
  z-index: 2;
}

.panel .fc-header .fc-button-prev {
  float: left;
}

.panel .fc-header .fc-button-next {
  float: right;
}

.panel .fc-header .fc-header-center {
  z-index: 1;
  width: 100%;
  padding: 0 40px;
}

.panel .fc-header .fc-header-right {
  position: absolute;
  top: -39px;
  right: 0;
}

.panel .fc-header .fc-state-default.fc-corner-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0;
}

.panel .fc-header .fc-state-disabled {
  opacity: 0.35;
  filter: alpha(opacity=35);
}

.panel .fc-content {
  margin: -1px;
}

.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: #ebeff6;
}

.fc-content .fc-event {
  border: none;
  padding: 4px 6px;
}

.fc-event {
  background-color: #5dcff3;
}

.datepicker td.active,
.datepicker td.active:hover,
.datepicker td.active:hover.active,
.datepicker td.active.active {
  background: #594f8d;
  background: #594f8d \9;
}

.wizard .badge-info {
  background-color: #5dcff3;
}

.wizard .badge-success {
  background-color: #92cf5c;
}

.wizard ul li.active {
  color: #5dcff3;
}

.select2-container .select2-choice {
  height: 30px;
  line-height: 28px;
  border-color: #d8dde5;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
}

.select2-container .select2-choice .select2-arrow {
  background-color: transparent;
  background-image: none;
  border-left: none;
}

.select2-container .select2-choice .select2-arrow b {
  background: none;
  position: relative;
}

.select2-container .select2-choice .select2-arrow b:after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #aaa;
}

.select2-container.select2-container-active .select2-choice {
  border-color: #5897fb;
}

.select2-container.select2-dropdown-open .select2-choice .select2-arrow b:after {
  content: "\f0d8";
}

.select2-container abbr {
  background: transparent;
}

.select2-container abbr:after {
  content: "\f00d";
  font-family: FontAwesome;
  position: absolute;
  top: -7px;
  font-size: 11px;
  color: #aaa;
}

.select2-search {
  position: relative;
  padding: 0 6px;
}

.select2-search:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  color: #aaa;
  right: 10px;
  top: 4px;
  width: 18px;
}

.select2-drop.select2-drop-above .select2-search:after {
  top: 8px;
}

.select2-search input {
  border-color: #d8dde5;
  background: #fff;
}

.select2-results .select2-highlighted {
  background-color: #5dcff3;
}

.select2-container-multi .select2-choices {
  border: 1px solid #d8dde5;
  border-radius: 3px;
  padding-top: 2px;
}

.select2-container-multi .select2-choices .select2-search-choice {
  border: 1px solid #d8dde5;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-search-choice-close {
  background: transparent;
}

.select2-search-choice-close:after {
  content: "\f00d";
  font-family: FontAwesome;
  font-size: 11px;
  color: #aaa;
  position: absolute;
  left: 2px;
  top: -1px;
}

#flotTip {
  padding: 3px 5px;
  background-color: #000;
  z-index: 100;
  color: #fff;
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #717171;
  background-color: #f3f5f9;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

a {
  color: #575757;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #7d7d7d;
  text-decoration: none;
}

.badge {
  background-color: #a7b4d3;
}

.badge.up {
  position: relative;
  top: -10px;
  padding: 3px 6px;
}

label {
  font-weight: 600;
}

.label-sm {
  padding-top: 0;
  padding-bottom: 0;
}

.text-primary {
  color: #594f8d;
}

.text-info {
  color: #5dcff3;
}

.text-success {
  color: #92cf5c;
}

.text-warning {
  color: #fcc44d;
}

.text-danger {
  color: #fb6b5b;
}

.text-white {
  color: #fff;
}

.text-muted {
  color: #979797;
}

small {
  font-size: 90%;
}

.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 2px 6px;
}

.badge-hollow {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: inherit;
}

.caret-white {
  border-top-color: #fff;
  border-top-color: rgba(255, 255, 255, 0.65);
}

a:hover .caret-white {
  border-top-color: #fff;
}

.tooltip-inner {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: #4c5566;
}

.tooltip.top .tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.9);
  border-top-color: #4c5566;
}

.tooltip.right .tooltip-arrow {
  border-right-color: rgba(0, 0, 0, 0.9);
  border-right-color: #4c5566;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.9);
  border-bottom-color: #4c5566;
}

.tooltip.left .tooltip-arrow {
  border-left-color: rgba(0, 0, 0, 0.9);
  border-left-color: #4c5566;
}

.popover-content {
  font-size: 12px;
  line-height: 1.5;
}

.progress-xs {
  height: 6px;
}

.progress-sm {
  height: 10px;
}

.progress-sm .progress-bar {
  font-size: 10px;
  line-height: 1em;
}

.breadcrumb {
  background-color: #fff;
  border: 1px solid #ebeff6;
  padding-left: 10px;
  font-size: 12px;
}

.accordion-group,
.accordion-inner {
  border-color: #ebeff6;
  border-radius: 2px;
}

.alert {
  font-size: 85%;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}

.alert .close i {
  font-size: 12px;
  font-weight: normal;
  display: block;
}

.form-control {
  border-color: #d8dde5;
}

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.input-s-lg {
  width: 250px;
}

.input-group-addon {
  border-color: #d8dde5;
  background-color: #f5f7fa;
}

.list-group {
  border-radius: 2px;
}

.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}

.list-group.no-borders .list-group-item {
  border: none;
}

.list-group.no-border .list-group-item {
  border-width: 1px 0;
}

.list-group.no-bg .list-group-item {
  background-color: transparent;
}

.list-group-item {
  border-color: #ebeff6;
  padding-right: 15px;
}

.list-group-item.media {
  margin-top: 0;
}

.list-group-item.active {
  border-color: #5dcff3 !important;
  background-color: #5dcff3 !important;
}

.list-group-item.active,
.list-group-item.active .text-muted {
  color: #ebf9fe;
}

.list-group-item.active a {
  color: #fff;
}

.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(0, 0, 0, 0.02);
}

.list-group-lg .list-group-item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.list-group-sp .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px;
}

.list-group-item>.badge {
  margin-right: 0;
}

.list-group-item>.fa-chevron-right {
  float: right;
  margin-top: 4px;
  margin-right: -5px;
}

.list-group-item>.fa-chevron-right+.badge {
  margin-right: 5px;
}

.nav-pills.no-radius>li>a {
  border-radius: 0;
}

.nav-pills>li.active>a {
  color: #fff !important;
  background-color: #5dcff3 !important;
}

.nav.nav-sm>li>a {
  padding: 6px 8px;
}

.nav>li>a .avatar {
  width: 30px;
}

.panel {
  border-radius: 2px;
  border-color: #ebeff6;
}

.panel.no-borders {
  border-width: 0;
}

.panel.no-borders .panel-heading,
.panel.no-borders .panel-footer {
  border-width: 0;
}

.panel .table-responsive {
  border-color: #e0e4e8;
}

.panel .table td,
.panel .table th {
  padding: 6px 15px;
  border-top: 1px solid #eaedef;
}

.panel .table thead>tr>th {
  border-bottom: 1px solid #e0e4e8;
}

.panel .table-striped>tbody>tr:nth-child(odd)>td,
.panel .table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #fcfdfe;
}

.panel .table-striped>thead th {
  background: #fafbfc;
  border-right: 1px solid #e0e4e8;
}

.panel .table-striped>thead th:last-child {
  border-right: none;
}

.panel-heading {
  border-color: #ebeff6;
  background-color: #fff;
  border-radius: 2px 2px 0 0;
}

.panel-heading.bg {
  margin: -1px -1px 0 -1px;
  border: none;
}

.panel-heading.bg .list-group-item {
  border-color: transparent;
}

.panel-heading.bg .list-group-item>a {
  color: #fff;
}

.panel-heading .nav {
  font-size: 13px;
  margin: -10px -15px -11px;
  border: none;
}

.panel-heading .nav>li>a {
  border-radius: 0;
  margin: 0;
  border-width: 0;
}

.panel-heading .nav-tabs.nav-justified {
  width: auto;
}

.panel-heading .nav-tabs.nav-justified>li:first-child>a,
.panel-heading .nav-tabs.pull-left>li:first-child>a {
  border-radius: 2px 0 0 0;
}

.panel-heading .nav-tabs.nav-justified>li:last-child>a,
.panel-heading .nav-tabs.pull-right>li:last-child>a {
  border-radius: 0 2px 0 0;
}

.panel-heading .nav-tabs>li>a {
  line-height: 1.5;
}

.panel-heading .nav-tabs>li>a:hover,
.panel-heading .nav-tabs>li>a:focus {
  border-width: 0;
  background: transparent;
  border-color: transparent;
}

.panel-heading .nav-tabs>li.active>a,
.panel-heading .nav-tabs>li.active>a:hover,
.panel-heading .nav-tabs>li.active>a:focus {
  color: #717171;
  background: #fff;
}

.panel-heading .list-group {
  background: transparent;
}

.panel-footer {
  border-color: #ebeff6;
  background-color: #fff;
  border-radius: 0 0 2px 2px;
}

.panel-group .panel-heading+.panel-collapse .panel-body {
  border-top: 1px solid #eaedef;
}

.open {
  z-index: 1050;
  position: relative;
}

.dropdown-menu {
  font-size: 13px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu.pull-left {
  left: 100%;
}

.dropdown-menu>.panel {
  border: none;
  margin: -5px 0;
}

.dropdown-menu>li>a {
  padding: 5px 15px;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  background-image: none;
  filter: none;
  background-color: #4c5566;
  color: #fff;
}

.dropdown-header {
  padding: 5px 15px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu:hover>a,
.dropdown-submenu:focus>a {
  background-color: #4c5566;
  color: #fff;
}

.dropdown-submenu:hover>.dropdown-menu,
.dropdown-submenu:focus>.dropdown-menu {
  display: block;
}

.dropdown-submenu.pull-left {
  float: none !important;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: 0;
  margin-left: -1px;
}

.dropup .dropdown-submenu>.dropdown-menu {
  top: auto;
  bottom: 0;
}

.dropdown-select>li>a input {
  position: absolute;
  left: -9999em;
}

.carousel-control {
  width: 40px;
  color: #999;
  text-shadow: none;
}

.carousel-control:hover,
.carousel-control:focus {
  color: #ccc;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
  filter: none;
}

.carousel-control i {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}

.carousel-indicators.out {
  bottom: -5px;
}

.carousel-indicators li {
  -webkit-transition: background-color .25s;
  transition: background-color .25s;
  background: #ddd;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
}

.carousel-indicators .active {
  background: #92cf5c;
  width: 10px;
  height: 10px;
  margin: 1px;
}

.carousel.carousel-fade .item {
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

.carousel.carousel-fade .active {
  opacity: 1;
  filter: alpha(opacity=1);
}

.carousel.carousel-fade .active.left,
.carousel.carousel-fade .active.right {
  left: 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}

.carousel.carousel-fade .next,
.carousel.carousel-fade .prev {
  left: 0;
  z-index: 1;
}

.carousel.carousel-fade .carousel-control {
  z-index: 3;
}

.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-0 {
  clear: left;
}

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.no-gutter [class*="col"] {
  padding: 0;
}

.modal-backdrop {
  background-color: #4c5566;
}

.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.modal-over {
  width: 100%;
  height: 100%;
  position: relative;
  background: #4c5566;
}

.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

.modal-content {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}

.icon-lg:before {
  vertical-align: -10%;
  font-size: 1.3333333333333333em;
}

.icon-muted {
  color: #d9d9d9;
  text-shadow: 0 1px 1px #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: transparent;
}

@media (orientation: landscape) {
  html.ios7.ipad>body {
      padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  html,
  body {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
  }
  .hbox {
      display: table;
      table-layout: fixed;
      border-spacing: 0;
      width: 100%;
  }
  .hbox>aside,
  .hbox>section {
      display: table-cell;
      vertical-align: top;
      height: 100%;
      padding: 0;
      float: none;
  }
  .hbox>aside.show,
  .hbox>aside.hidden-sm,
  .hbox>section.show,
  .hbox>section.hidden-sm {
      display: table-cell !important;
  }
  .hbox.stretch {
      height: 100%;
  }
  .vbox {
      display: table;
      border-spacing: 0;
      position: relative;
      height: 100%;
      width: 100%;
  }
  .vbox>section,
  .vbox>footer {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
  }
  .vbox>header~section {
      top: 50px;
  }
  .vbox>section.w-f {
      bottom: 50px;
  }
  .vbox>footer {
      top: auto;
      z-index: 1000;
  }
  .vbox>footer~section {
      bottom: 50px;
  }
  .vbox.flex>header,
  .vbox.flex>section,
  .vbox.flex>footer {
      position: inherit;
  }
  .vbox.flex>section {
      display: table-row;
      height: 100%;
  }
  .vbox.flex>section>section {
      position: relative;
      height: 100%;
      overflow: auto;
  }
  .ie .vbox.flex>section>section {
      display: table-cell;
  }
  .vbox.flex>section>section>section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
  }
  .aside-xs {
      width: 48px;
  }
  .aside {
      width: 180px;
  }
  .aside-sm {
      width: 150px;
  }
  .aside-md {
      width: 200px;
  }
  .aside-lg {
      width: 250px;
  }
  .aside-xl {
      width: 300px;
  }
  .scrollable {
      -webkit-overflow-scrolling: touch;
  }
}

.hbox>aside,
.hbox>section {
  padding: 0 !important;
}

.header,
.footer {
  min-height: 50px;
  padding: 0 15px;
}

.header>p,
.footer>p {
  margin-top: 15px;
  display: inline-block;
}

.header>.btn,
.header>.btn-group,
.header>.btn-toolbar,
.footer>.btn,
.footer>.btn-group,
.footer>.btn-toolbar {
  margin-top: 10px;
}

.header>.btn-lg,
.footer>.btn-lg {
  margin-top: 0;
}

.header .nav-tabs,
.footer .nav-tabs {
  border: none;
  margin-left: -15px;
  margin-right: -15px;
}

.header .nav-tabs>li a,
.footer .nav-tabs>li a {
  border: none !important;
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
}

.header .nav-tabs>li a:hover,
.header .nav-tabs>li a:focus,
.footer .nav-tabs>li a:hover,
.footer .nav-tabs>li a:focus {
  background-color: transparent;
}

.header .nav-tabs>li.active a,
.footer .nav-tabs>li.active a {
  color: #717171;
}

.header .nav-tabs>li.active a,
.header .nav-tabs>li.active a:hover,
.footer .nav-tabs>li.active a,
.footer .nav-tabs>li.active a:hover {
  background-color: #f3f5f9;
}

.header .nav-tabs.nav-white>li.active a,
.header .nav-tabs.nav-white>li.active a:hover,
.footer .nav-tabs.nav-white>li.active a,
.footer .nav-tabs.nav-white>li.active a:hover {
  background-color: #fff;
}

.header.navbar,
.footer.navbar {
  min-height: 0;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow: visible;
  overflow-y: auto;
}

@media print {
  html,
  body,
  .hbox,
  .vbox {
      height: auto;
  }
  .vbox>section,
  .vbox>footer {
      position: relative;
  }
}

html.layout-box {
  padding: 30px;
  background-color: #161719;
}

html.layout-box,
html.layout-box body,
html.layout-box .hbox,
html.layout-box .vbox {
  height: auto;
}

html.layout-box .vbox>section,
html.layout-box .vbox>footer {
  position: static;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(50, 50, 50, 0.6);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.1);
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(50, 50, 50, 0.2);
}

.nav-bar {
  position: relative;
}

.nav-bar>.btn {
  position: absolute;
  font-size: 1.3em;
  padding: 9px 12px;
  line-height: 30px;
}

.nav-bar .nav-brand+.btn {
  right: 0;
  top: 0;
}

.nav-brand {
  text-align: center;
  font-size: 25px;
  line-height: 50px;
  display: block;
}

.nav-brand:hover {
  text-decoration: none;
}

.nav-brand img {
  max-height: 20px;
  margin-top: -7px;
  vertical-align: middle;
}

.nav-msg {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
  padding: 5px;
}

.nav-msg .dropdown-menu {
  top: -13px;
  margin-left: 9px;
  width: 300px;
}

.nav-msg .arrow.left {
  top: 25px;
}

.nav-user {
  padding: 10px 15px;
}

.nav-user a:focus {
  outline: 0;
}

.nav-primary:before,
.nav-primary:after {
  content: " ";
  display: table;
}

.nav-primary:after {
  clear: both;
}

.nav-primary li {
  line-height: 1.5;
}

.nav-primary li a i {
  margin-right: 0.5em;
  font-size: 14px;
}

.nav-primary>ul>li>a {
  padding: 15px 15px;
  position: relative;
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
  transition: color .3s ease-in-out 0s;
}

.no-borders .nav-primary>ul>li>a {
  border-width: 0 !important;
}

.nav-primary>ul>li>a>.badge {
  font-size: 11px;
  padding: 3px 6px;
}

.bg-light .nav-primary>ul>li>a {
  text-shadow: 0 1px 1px #fff;
}

.bg-light .nav-primary>ul>li>a:hover {
  background-color: #4c5566 !important;
}

.nav-primary>ul>li:first-child>a {
  border-top-width: 0;
}

.nav-primary>ul>li.dropdown-submenu:hover>a,
.nav-primary>ul>li.dropdown-submenu:focus>a,
.nav-primary>ul>li>a:hover,
.nav-primary>ul>li>a:focus,
.nav-primary>ul>li>a:active,
.nav-primary>ul>li.active>a {
  color: #fff;
  background-color: inherit;
  background-color: rgba(0, 0, 0, 0.075) !important;
  text-shadow: none;
}

.bg-light .nav-primary>ul>li.dropdown-submenu:hover>a,
.bg-light .nav-primary>ul>li.dropdown-submenu:focus>a,
.bg-light .nav-primary>ul>li.active>a {
  background-color: #4c5566 !important;
}

.nav-primary>ul>li.dropdown-submenu>a:after {
  display: none;
}

.nav-primary>ul>li>.dropdown-menu {
  border-radius: 0;
}

@media (min-width: 768px) {
  #nav {
      z-index: 1000;
      -webkit-transition: width .25s;
      transition: width .25s;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
  }
  #nav .nav-avatar .thumb-sm {
      width: 30px;
  }
  #nav .nav-avatar .arrow {
      left: 15px;
  }
  #nav>.vbox>.footer {
      box-shadow: inset 0 1px 0 rgba(40, 50, 60, 0.15);
  }
  #nav.nav-vertical {
      width: 80px;
  }
  #nav.nav-vertical.only-icon {
      width: 60px;
  }
  #nav.nav-vertical.only-icon .nav-primary>ul>li>a i {
      margin: 5px 0;
  }
  #nav.nav-vertical.only-icon .nav-primary>ul>li>a span {
      display: none;
  }
  #nav.nav-vertical.only-icon .nav-avatar .arrow {
      left: 12px !important;
  }
  #nav.nav-vertical .nav-avatar .caret {
      display: none;
  }
  #nav.nav-vertical .nav-avatar>.thumb-sm {
      width: 100%;
  }
  #nav.nav-vertical .nav-avatar .arrow {
      left: 27px !important;
  }
  #nav.nav-vertical .nav-msg {
      top: 5px;
  }
  #nav.nav-vertical .nav-msg .arrow.left {
      top: 20px;
  }
  #nav.nav-vertical .nav-msg .dropdown-menu {
      top: -8px;
  }
  #nav.nav-vertical .nav-brand {
      font-size: 20px;
      padding-left: 0;
      padding-right: 0;
  }
  #nav.nav-vertical .nav-primary>ul>li>a {
      position: relative;
      padding: 12px 0;
      font-size: 11px;
      text-align: center;
  }
  #nav.nav-vertical .nav-primary>ul>li>a span {
      display: block;
  }
  #nav.nav-vertical .nav-primary>ul>li>a i {
      display: block;
      font-size: 19px;
      margin: 4px 0;
  }
  #nav.nav-vertical .nav-primary>ul>li>a .badge {
      position: absolute;
      right: 10px;
      top: 10px;
  }
  #nav.nav-vertical>.vbox>.footer {
      padding-left: 10px;
      padding-right: 10px;
  }
  #nav.nav-vertical .hidden-vertical {
      display: none;
  }
}

@media (max-width: 767px) {
  .nav-user {
      text-align: center;
  }
  .nav-user .nav-avatar {
      margin-top: 20px;
      display: inline-block;
  }
  .nav-user .nav-avatar .thumb-sm {
      position: relative;
      width: 160px;
      background-color: rgba(255, 255, 255, 0.15);
      padding: 5px;
  }
  .nav-user .nav-avatar .thumb-sm:before {
      content: "";
      position: absolute;
      left: 5px;
      right: 5px;
      bottom: 5px;
      top: 5px;
      border: 4px solid #fff;
      border-radius: 500px;
  }
  .nav-user .nav-avatar .thumb-sm .caret {
      display: none;
  }
  .nav-user .dropdown-menu {
      text-align: left;
      margin-top: 0;
  }
  .nav-user .nav-msg {
      right: auto;
      left: 10px;
  }
  .nav-user .nav-msg .dropdown-menu {
      top: -6px;
      width: 260px;
  }
  .nav-user .nav-msg .arrow.left {
      top: 20px;
  }
  .nav-bar-fixed-top {
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 1100;
  }
  .nav-bar-fixed-top+* {
      padding-top: 50px;
  }
  .nav-bar-fixed-bottom {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1100;
  }
  html,
  body {
      overflow-x: hidden;
      min-height: 100%;
  }
  .nav-primary .dropdown-menu {
      position: relative;
      float: none;
      left: 0;
      margin-left: 0;
      padding: 0;
  }
  .nav-primary .dropdown-menu a {
      padding: 15px;
      border-bottom: 1px solid #eee;
  }
  .nav-primary .dropdown-menu li:last-child a {
      border-bottom: none;
  }
  .nav-off-screen {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 75%;
      visibility: visible;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
  }
  .nav-off-screen .nav-primary {
      display: block !important;
  }
  .nav-off-screen .nav-bar-fixed-top {
      width: 75%;
  }
  .nav-off-screen.push-right {
      left: auto;
      right: 0;
  }
  .nav-off-screen.push-right+* {
      -webkit-transform: translate3d(-75%, 0px, 0px);
      transform: translate3d(-75%, 0px, 0px);
  }
  .nav-off-screen+* {
      background-color: #f3f5f9;
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      -moz-transition: -moz-transform 0.2s ease-in-out;
      -o-transition: -o-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translate3d(75%, 0px, 0px);
      transform: translate3d(75%, 0px, 0px);
      overflow: hidden;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
  }
  .nav-off-screen+* .nav-off-screen-block {
      display: block !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1950;
  }
  .slimScrollDiv,
  .slim-scroll {
      overflow: visible !important;
      height: auto !important;
  }
  .slimScrollBar,
  .slimScrollRail {
      display: none !important;
  }
}

.arrow {
  border-width: 8px;
  z-index: 10;
}

.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.arrow:after {
  border-width: 7px;
  content: "";
}

.arrow.top {
  left: 50%;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #eee;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  top: -8px;
}

.arrow.top:after {
  content: " ";
  top: 1px;
  margin-left: -7px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.arrow.right {
  top: 50%;
  right: -8px;
  margin-top: -8px;
  border-right-width: 0;
  border-left-color: #eee;
  border-left-color: rgba(0, 0, 0, 0.1);
}

.arrow.right:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -7px;
}

.arrow.bottom {
  left: 50%;
  margin-left: -8px;
  border-bottom-width: 0;
  border-top-color: #eee;
  border-top-color: rgba(0, 0, 0, 0.1);
  bottom: -8px;
}

.arrow.bottom:after {
  content: " ";
  bottom: 1px;
  margin-left: -7px;
  border-bottom-width: 0;
  border-top-color: #fff;
}

.arrow.left {
  top: 50%;
  left: -8px;
  margin-top: -8px;
  border-left-width: 0;
  border-right-color: #eee;
  border-right-color: rgba(0, 0, 0, 0.1);
}

.arrow.left:after {
  content: " ";
  left: 1px;
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -7px;
}

.btn-default {
  color: #fff !important;
  background-color: #aeb6cb;
  border-color: #aeb6cb;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #fff !important;
  background-color: #95a0bb;
  border-color: #8994b3;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #aeb6cb;
  border-color: #aeb6cb;
}

.btn-default .caret {
  border-top-color: #fff;
}

.btn-twitter {
  color: #fff !important;
  background-color: #00c7f7;
  border-color: #00c7f7;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open .dropdown-toggle.btn-twitter {
  color: #fff !important;
  background-color: #00a6ce;
  border-color: #0096ba;
}

.btn-twitter:active,
.btn-twitter.active,
.open .dropdown-toggle.btn-twitter {
  background-image: none;
}

.btn-twitter.disabled,
.btn-twitter.disabled:hover,
.btn-twitter.disabled:focus,
.btn-twitter.disabled:active,
.btn-twitter.disabled.active,
.btn-twitter[disabled],
.btn-twitter[disabled]:hover,
.btn-twitter[disabled]:focus,
.btn-twitter[disabled]:active,
.btn-twitter[disabled].active,
fieldset[disabled] .btn-twitter,
fieldset[disabled] .btn-twitter:hover,
fieldset[disabled] .btn-twitter:focus,
fieldset[disabled] .btn-twitter:active,
fieldset[disabled] .btn-twitter.active {
  background-color: #00c7f7;
  border-color: #00c7f7;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  background-color: #fcfcfe;
}

.btn-link {
  color: #717171;
}

.btn-link.active {
  webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  color: #fff !important;
  background-color: #594f8d;
  border-color: #594f8d;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff !important;
  background-color: #484073;
  border-color: #403966;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #594f8d;
  border-color: #594f8d;
}

.btn-success {
  color: #fff !important;
  background-color: #92cf5c;
  border-color: #92cf5c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff !important;
  background-color: #7dc63c;
  border-color: #73b836;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #92cf5c;
  border-color: #92cf5c;
}

.btn-info {
  color: #fff !important;
  background-color: #5dcff3;
  border-color: #5dcff3;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #37c4f0;
  border-color: #24beef;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #5dcff3;
  border-color: #5dcff3;
}

.btn-warning {
  color: #fff !important;
  background-color: #fcc44d;
  border-color: #fcc44d;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff !important;
  background-color: #fbb725;
  border-color: #fbb011;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #fcc44d;
  border-color: #fcc44d;
}

.btn-danger {
  color: #fff !important;
  background-color: #fb6b5b;
  border-color: #fb6b5b;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff !important;
  background-color: #fa4733;
  border-color: #fa351f;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #fb6b5b;
  border-color: #fb6b5b;
}

.btn-facebook {
  color: #fff !important;
  background-color: #335397;
  border-color: #335397;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
  color: #fff !important;
  background-color: #294279;
  border-color: #243a69;
}

.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
  background-image: none;
}

.btn-facebook.disabled,
.btn-facebook.disabled:hover,
.btn-facebook.disabled:focus,
.btn-facebook.disabled:active,
.btn-facebook.disabled.active,
.btn-facebook[disabled],
.btn-facebook[disabled]:hover,
.btn-facebook[disabled]:focus,
.btn-facebook[disabled]:active,
.btn-facebook[disabled].active,
fieldset[disabled] .btn-facebook,
fieldset[disabled] .btn-facebook:hover,
fieldset[disabled] .btn-facebook:focus,
fieldset[disabled] .btn-facebook:active,
fieldset[disabled] .btn-facebook.active {
  background-color: #335397;
  border-color: #335397;
}

.btn-gplus {
  color: #fff !important;
  background-color: #dd4a38;
  border-color: #dd4a38;
}

.btn-gplus:hover,
.btn-gplus:focus,
.btn-gplus:active,
.btn-gplus.active,
.open .dropdown-toggle.btn-gplus {
  color: #fff !important;
  background-color: #ca3522;
  border-color: #b8301f;
}

.btn-gplus:active,
.btn-gplus.active,
.open .dropdown-toggle.btn-gplus {
  background-image: none;
}

.btn-gplus.disabled,
.btn-gplus.disabled:hover,
.btn-gplus.disabled:focus,
.btn-gplus.disabled:active,
.btn-gplus.disabled.active,
.btn-gplus[disabled],
.btn-gplus[disabled]:hover,
.btn-gplus[disabled]:focus,
.btn-gplus[disabled]:active,
.btn-gplus[disabled].active,
fieldset[disabled] .btn-gplus,
fieldset[disabled] .btn-gplus:hover,
fieldset[disabled] .btn-gplus:focus,
fieldset[disabled] .btn-gplus:active,
fieldset[disabled] .btn-gplus.active {
  background-color: #dd4a38;
  border-color: #dd4a38;
}

.btn-white {
  border-color: #dae0ed;
  border-color: rgba(150, 160, 180, 0.3);
  color: #717171 !important;
  background-color: #fff;
  box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
}

.btn {
  font-weight: 500;
}

.bg .btn-white {
  border-color: rgba(0, 0, 0, 0.1);
}

.btn-white .caret {
  border-top-color: #656565;
}

.dropup .btn-white .caret {
  border-bottom-color: #656565;
}

.btn-icon {
  padding-left: 0;
  padding-right: 0;
  width: 34px;
  text-align: center;
}

.btn-sm.btn-icon {
  width: 30px;
}

.text-active,
.active>.text,
.active .auto .text {
  display: none !important;
}

.active>.text-active,
.active .auto .text-active {
  display: inline-block !important;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  text-decoration: none;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.icon-stack [class^="icon-"]:before,
.icon-stack [class*=" icon-"]:before {
  margin: 0;
}

.icon-xlarge:before {
  font-size: 1.5em;
  vertical-align: -14%;
}

.btn-group-justified {
  border-collapse: separate;
}

.btn-rounded {
  border-radius: 50px;
}

.btn>i.pull-left,
.btn>i.pull-right {
  line-height: 1.428571429;
}

.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}

.chat-item:before,
.chat-item:after {
  content: " ";
  display: table;
}

.chat-item:after {
  clear: both;
}

.chat-item .arrow {
  top: 20px;
}

.chat-item .arrow.right:after {
  border-left-color: #92cf5c;
}

.chat-item .chat-body {
  position: relative;
  margin-left: 45px;
  min-height: 30px;
}

.chat-item .chat-body .panel {
  margin: 0 -1px;
}

.chat-item.right .chat-body {
  margin-left: 0;
  margin-right: 45px;
}

.chat-item+.chat-item {
  margin-top: 15px;
}

.comment-list {
  position: relative;
}

.comment-list .comment-item {
  margin-top: 0;
  position: relative;
}

.comment-list .comment-item>.thumb-sm {
  width: 36px;
}

.comment-list .comment-item .arrow.left {
  top: 20px;
  left: 39px;
}

.comment-list .comment-item .comment-body {
  margin-left: 46px;
}

.comment-list .comment-item .panel-body {
  padding: 10px 15px;
}

.comment-list .comment-item .panel-heading,
.comment-list .comment-item .panel-footer {
  position: relative;
  font-size: 12px;
}

.comment-list .comment-reply {
  margin-left: 46px;
}

.comment-list:before {
  position: absolute;
  top: 0;
  bottom: 35px;
  left: 18px;
  width: 1px;
  background: #e0e4e8;
  content: '';
}

.timeline {
  display: table;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  position: relative;
  border-collapse: collapse;
}

.timeline:before {
  content: "";
  width: 6px;
  margin-left: -4px;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 30px;
  background-color: #ddd;
  z-index: 0;
}

.timeline .timeline-date {
  position: absolute;
  width: 150px;
  left: -200px;
  top: 50%;
  margin-top: -9px;
  text-align: right;
}

.timeline .timeline-icon {
  position: absolute;
  left: -41px;
  top: -2px;
  top: 50%;
  margin-top: -15px;
}

.timeline .time-icon {
  width: 30px;
  height: 30px;
  display: inline-block !important;
  z-index: 10;
  border: 2px solid #fff;
  border-radius: 20px;
  text-align: center;
  line-height: 28px;
}

.timeline .time-icon:before {
  font-size: 16px;
}

.timeline-item {
  display: table-row;
}

.timeline-item:before,
.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}

.timeline-item.alt {
  text-align: right;
}

.timeline-item.alt:before {
  display: none;
}

.timeline-item.alt .panel {
  margin-right: 25px;
  margin-left: 0;
}

.timeline-item.alt .timeline-date {
  left: auto;
  right: -200px;
  text-align: left;
}

.timeline-item.alt .timeline-icon {
  left: auto;
  right: -41px;
}

.timeline-item.active {
  display: table-caption;
  text-align: center;
}

.timeline-item.active:before {
  width: 1%;
}

.timeline-item.active .timeline-caption {
  display: inline-block;
  width: auto;
}

.timeline-item.active .timeline-caption h5 span {
  color: #fff;
}

.timeline-item.active .panel {
  margin-left: 0;
}

.timeline-item.active .timeline-date,
.timeline-item.active .timeline-icon {
  position: static;
  margin-bottom: 10px;
  display: inline-block;
  width: auto;
}

.timeline-caption {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}

.timeline-caption .panel {
  display: inline-block;
  position: relative;
  margin-left: 25px;
  text-align: left;
}

.timeline-caption h5 {
  margin: 0;
}

.timeline-caption h5 span {
  display: block;
  color: #999;
  margin-bottom: 4px;
  font-size: 12px;
}

.timeline-caption p {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 10px;
}

.timeline-footer {
  display: table-row;
}

.timeline-footer a {
  display: table-cell;
  text-align: right;
}

.timeline-footer .time-icon {
  margin-right: -15px;
  z-index: 5;
}

#note-list .note-name,
#note-list .note-desc {
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#note-list .note-desc {
  height: 16px;
  font-size: 11px;
}

#note-list li {
  cursor: pointer;
}

#task-list .edit {
  /*display: none;*/
  display: block;
  float: left;
  position: relative;
  left: 0;
  top: -8px;
  border-width: 0;
  background: transparent;
  box-shadow: none;
  padding: 0 30px 0 0;
  font-size: 13px;
  color: #fff;
  width: 50%;
}

#task-list .editing .task-name {
  display: none;
}

#task-list .editing .edit {
  display: block;
}

#task-list .checkbox {
  padding-left: 20px;
  margin: 4px 0;
}

#task-list li {
  position: relative;
}

#task-list li .close {
  position: absolute;
  top: 13px;
  right: 15px;
  z-index: 1;
}

#task-list li.done .task-name {
  text-decoration: line-through;
}

#task-detail textarea {
  height: 60px;
  font-size: 12px;
  border-radius: 0;
}

.paper {
  position: relative;
  background: -webkit-linear-gradient(to bottom, #f0f0f0 0%, white 5%) 0 0;
  background: -moz-linear-gradient(to bottom, #f0f0f0 0%, white 5%) 0 0;
  background: linear-gradient(to bottom, #f0f0f0 0%, white 5%) 0 0;
  -webkit-background-size: 100% 30px;
  -moz-background-size: 100% 30px;
  -ms-background-size: 100% 30px;
  background-size: 100% 30px;
}

.paper:before {
  content: '';
  position: absolute;
  width: 0px;
  top: 0;
  left: 39px;
  bottom: 0;
  border-left: 1px solid #F9D3D3;
}

.paper textarea {
  border: none;
  background-color: transparent;
  height: 100%;
  padding: 30px 0 0 55px;
  line-height: 30px;
  min-height: 210px;
}

.tags .label {
  font-size: 1em;
  display: inline-block;
  padding: 6px 10px;
  margin-bottom: 3px;
}

.post-item {
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
}

.post-item .post-title {
  margin-top: 0;
}

.post-item .post-media {
  text-align: center;
}

.post-item .post-media img {
  border-radius: 3px 3px 0 0;
}

.switch {
  cursor: pointer;
  position: relative;
}

.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.switch input:checked+span {
  background-color: #92cf5c;
}

.switch input:checked+span:after {
  left: 31px;
}

.switch span {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 26px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}

.bg-gradient {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(rgba(40, 50, 60, 0)), to(rgba(40, 50, 60, 0.05)));
  background-image: -webkit-linear-gradient(to bottom, rgba(40, 50, 60, 0), 0, rgba(40, 50, 60, 0.05), 100%);
  background-image: -moz-linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.05) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.05) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#0c28323c', GradientType=0);
  filter: none;
}

.bg-light {
  background-color: #ebeef5;
  color: #717171;
}

.bg-light.lt,
.bg-light .lt {
  background-color: #f2f4f8;
}

.bg-light.lter,
.bg-light .lter {
  background-color: #f9fafc;
}

.bg-light.dk,
.bg-light .dk {
  background-color: #e4e8f2;
}

.bg-light.dker,
.bg-light .dker {
  background-color: #dde2ee;
}

.bg-dark {
  background-color: #4c5566;
  color: #c4c9d3;
}

.bg-dark.lt,
.bg-dark .lt {
  background-color: #545e70;
}

.bg-dark.lter,
.bg-dark .lter {
  background-color: #5c677c;
}

.bg-dark.dk,
.bg-dark .dk {
  background-color: #444c5c;
}

.bg-dark.dker,
.bg-dark .dker {
  background-color: #3d4452;
}

.bg-dark a {
  color: #d3d7de;
}

.bg-dark a:hover {
  color: #fff;
}

.bg-dark a.list-group-item:hover,
.bg-dark a.list-group-item:focus {
  background-color: inherit;
}

.bg-dark .nav li>a {
  color: #a7afbd;
}

.bg-dark .nav li>a:hover {
  background-color: #414957;
}

.bg-dark .nav li>ul.dropdown-menu>li>a {
  color: #626d83;
}

.bg-dark .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-dark .nav .open>a {
  background-color: #414957;
}

.bg-dark.navbar .nav>li.active>a {
  color: #fff;
  background-color: #414957;
}

.bg-dark .open>a,
.bg-dark .open>a:hover,
.bg-dark .open>a:focus {
  color: #fff;
}

.bg-dark .text-muted {
  color: #b6bcc8;
}

.bg-black {
  background-color: #2c3039;
  color: #9aa1b1;
}

.bg-black.lt,
.bg-black .lt {
  background-color: #343843;
}

.bg-black.lter,
.bg-black .lter {
  background-color: #3d424f;
}

.bg-black.dk,
.bg-black .dk {
  background-color: #24282f;
}

.bg-black.dker,
.bg-black .dker {
  background-color: #1c1f25;
}

.bg-black a {
  color: #a8aebc;
}

.bg-black a:hover {
  color: #fff;
}

.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
  background-color: inherit;
}

.bg-black .nav li>a {
  color: #7d869b;
}

.bg-black .nav li>a:hover {
  background-color: #21242b;
}

.bg-black .nav li>ul.dropdown-menu>li>a {
  color: #424856;
}

.bg-black .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-black .nav .open>a {
  background-color: #21242b;
}

.bg-black.navbar .nav>li.active>a {
  color: #fff;
  background-color: #21242b;
}

.bg-black .open>a,
.bg-black .open>a:hover,
.bg-black .open>a:focus {
  color: #fff;
}

.bg-black .text-muted {
  color: #8b93a6;
}

.bg-primary {
  background-color: #594f8d;
  color: #dbd8e9;
}

.bg-primary.lt,
.bg-primary .lt {
  background-color: #605598;
}

.bg-primary.lter,
.bg-primary .lter {
  background-color: #695ea5;
}

.bg-primary.dk,
.bg-primary .dk {
  background-color: #524982;
}

.bg-primary.dker,
.bg-primary .dker {
  background-color: #4b4276;
}

.bg-primary a {
  color: #eae9f2;
}

.bg-primary a:hover {
  color: #fff;
}

.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
  background-color: inherit;
}

.bg-primary .nav li>a {
  color: #bdb8d7;
}

.bg-primary .nav li>a:hover {
  background-color: #4f467d;
}

.bg-primary .nav li>ul.dropdown-menu>li>a {
  color: #7166a9;
}

.bg-primary .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-primary .nav .open>a {
  background-color: #4f467d;
}

.bg-primary.navbar .nav>li.active>a {
  color: #fff;
  background-color: #4f467d;
}

.bg-primary .open>a,
.bg-primary .open>a:hover,
.bg-primary .open>a:focus {
  color: #fff;
}

.bg-primary .text-muted {
  color: #ccc8e0;
}

.bg-success {
  background-color: #92cf5c;
  color: #fbfdfa;
}

.bg-success.lt,
.bg-success .lt {
  background-color: #9bd36a;
}

.bg-success.lter,
.bg-success .lter {
  background-color: #a6d87a;
}

.bg-success.dk,
.bg-success .dk {
  background-color: #89cb4e;
}

.bg-success.dker,
.bg-success .dker {
  background-color: #80c740;
}

.bg-success a {
  color: #ffffff;
}

.bg-success a:hover {
  color: #fff;
}

.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
  background-color: inherit;
}

.bg-success .nav li>a {
  color: #eef8e6;
}

.bg-success .nav li>a:hover {
  background-color: #85c948;
}

.bg-success .nav li>ul.dropdown-menu>li>a {
  color: #5f982d;
}

.bg-success .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-success .nav .open>a {
  background-color: #85c948;
}

.bg-success.navbar .nav>li.active>a {
  color: #fff;
  background-color: #85c948;
}

.bg-success .open>a,
.bg-success .open>a:hover,
.bg-success .open>a:focus {
  color: #fff;
}

.bg-success .text-muted {
  color: #eef8e6;
}

.bg-info {
  background-color: #5dcff3;
  color: #ffffff;
}

.bg-info.lt,
.bg-info .lt {
  background-color: #6ed4f4;
}

.bg-info.lter,
.bg-info .lter {
  background-color: #81daf6;
}

.bg-info.dk,
.bg-info .dk {
  background-color: #4ccaf2;
}

.bg-info.dker,
.bg-info .dker {
  background-color: #3cc5f1;
}

.bg-info a {
  color: #ffffff;
}

.bg-info a:hover {
  color: #fff;
}

.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
  background-color: inherit;
}

.bg-info .nav li>a {
  color: #ffffff;
}

.bg-info .nav li>a:hover {
  background-color: #45c8f1;
}

.bg-info .nav li>ul.dropdown-menu>li>a {
  color: #10a9da;
}

.bg-info .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-info .nav .open>a {
  background-color: #45c8f1;
}

.bg-info.navbar .nav>li.active>a {
  color: #fff;
  background-color: #45c8f1;
}

.bg-info .open>a,
.bg-info .open>a:hover,
.bg-info .open>a:focus {
  color: #fff;
}

.bg-info .text-muted {
  color: #ffffff;
}

.bg-warning {
  background-color: #fcc44d;
  color: #ffffff;
}

.bg-warning.lt,
.bg-warning .lt {
  background-color: #fcca5f;
}

.bg-warning.lter,
.bg-warning .lter {
  background-color: #fdd073;
}

.bg-warning.dk,
.bg-warning .dk {
  background-color: #fcbe3b;
}

.bg-warning.dker,
.bg-warning .dker {
  background-color: #fbb82a;
}

.bg-warning a {
  color: #ffffff;
}

.bg-warning a:hover {
  color: #fff;
}

.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
  background-color: inherit;
}

.bg-warning .nav li>a {
  color: #fffefd;
}

.bg-warning .nav li>a:hover {
  background-color: #fcbc34;
}

.bg-warning .nav li>ul.dropdown-menu>li>a {
  color: #df9904;
}

.bg-warning .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-warning .nav .open>a {
  background-color: #fcbc34;
}

.bg-warning.navbar .nav>li.active>a {
  color: #fff;
  background-color: #fcbc34;
}

.bg-warning .open>a,
.bg-warning .open>a:hover,
.bg-warning .open>a:focus {
  color: #fff;
}

.bg-warning .text-muted {
  color: #fffefd;
}

.bg-danger {
  background-color: #fb6b5b;
  color: #ffffff;
}

.bg-danger.lt,
.bg-danger .lt {
  background-color: #fb7b6c;
}

.bg-danger.lter,
.bg-danger .lter {
  background-color: #fc8d80;
}

.bg-danger.dk,
.bg-danger .dk {
  background-color: #fb5b4a;
}

.bg-danger.dker,
.bg-danger .dker {
  background-color: #fa4c38;
}

.bg-danger a {
  color: #ffffff;
}

.bg-danger a:hover {
  color: #fff;
}

.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
  background-color: inherit;
}

.bg-danger .nav li>a {
  color: #ffffff;
}

.bg-danger .nav li>a:hover {
  background-color: #fa5542;
}

.bg-danger .nav li>ul.dropdown-menu>li>a {
  color: #ea1d06;
}

.bg-danger .nav li>ul.dropdown-menu>li>a:hover {
  color: #fff;
}

.bg-danger .nav .open>a {
  background-color: #fa5542;
}

.bg-danger.navbar .nav>li.active>a {
  color: #fff;
  background-color: #fa5542;
}

.bg-danger .open>a,
.bg-danger .open>a:hover,
.bg-danger .open>a:focus {
  color: #fff;
}

.bg-danger .text-muted {
  color: #ffffff;
}

.bg-white {
  background-color: #fff;
  color: #717171;
}

.bg-white a {
  color: #575757;
}

.bg-white a:hover {
  color: #3e3e3e;
}

.bg-white .text-muted {
  color: #979797;
}

.bg {
  color: #fff;
}

.bg a,
.bg a:hover {
  color: #fff;
}

.pos-rlt {
  position: relative;
}

.pos-stc {
  position: static;
}

.pos-abt {
  position: absolute;
}

.line {
  *width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
  background-color: transparent;
  border-width: 0;
  border-top: 1px solid #eaedef;
}

.line-xs {
  margin: 0;
}

.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}

.line-dashed {
  border-style: dashed;
  background: transparent;
}

.no-line {
  border-width: 0;
}

.no-border,
.no-borders {
  border-color: transparent !important;
  border-width: 0;
}

.no-radius {
  border-radius: 0;
}

.block {
  display: block;
}

.block.hide {
  display: none;
}

.inline {
  display: inline-block;
}

.pull-right-lg {
  float: right;
}

.none {
  display: none;
}

.pull-none {
  float: none;
}

.form-control-trans {
  border: none;
  background: transparent;
}

.bg-white-only {
  background-color: #fff;
}

.rounded {
  border-radius: 500px;
}

.btn-s-xs {
  min-width: 90px;
}

.btn-s-sm {
  min-width: 100px;
}

.btn-s-md {
  min-width: 120px;
}

.btn-s-lg {
  min-width: 150px;
}

.btn-s-xl {
  min-width: 200px;
}

.l-h-2x {
  line-height: 2em;
}

.l-h-1x {
  line-height: 1.2;
}

.v-middle {
  vertical-align: middle !important;
}

.v-top {
  vertical-align: top;
}

.v-bottom {
  vertical-align: bottom;
}

.font-thin {
  font-weight: 300;
}

.font-normal {
  font-weight: normal;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-sm {
  font-size: 12px;
}

.text-xs {
  font-size: 10px;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-uc {
  text-transform: uppercase;
}

.wrapper {
  padding: 15px;
}

.wrapper-lg {
  padding: 30px;
}

.wrapper-xl {
  padding: 50px;
}

.padder {
  padding-left: 15px;
  padding-right: 15px;
}

.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.no-padder {
  padding: 0 !important;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.pull-out {
  margin: -10px -15px;
}

.b-t {
  border-top: 1px solid #e0e4e8;
}

.b-r {
  border-right: 1px solid #e0e4e8;
}

.b-b {
  border-bottom: 1px solid #e0e4e8;
}

.b-l {
  border-left: 1px solid #e0e4e8;
}

.r {
  border-radius: 2px 2px 2px 2px;
}

.r-l {
  border-radius: 2px 0 0 2px;
}

.r-r {
  border-radius: 0 2px 2px 0;
}

.r-t {
  border-radius: 2px 2px 0 0;
}

.r-b {
  border-radius: 0 0 2px 2px;
}

.m {
  margin: 15px;
}

.m-n {
  margin: 0;
}

.m-l {
  margin-left: 15px;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-lg {
  margin-left: 20px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n-lg {
  margin-left: -20px;
}

.m-t {
  margin-top: 15px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-lg {
  margin-top: 20px;
}

.m-t-n {
  margin-top: -15px !important;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n-lg {
  margin-top: -20px;
}

.m-r {
  margin-right: 15px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-lg {
  margin-right: 20px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n-lg {
  margin-right: -20px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-lg {
  margin-bottom: 20px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n-lg {
  margin-bottom: -20px;
}

.media-xs {
  min-width: 50px;
}

.media-sm {
  min-width: 80px;
}

.media-md {
  min-width: 90px;
}

.media-lg {
  min-width: 120px;
}

.thumb {
  width: 64px;
  display: inline-block;
}

.thumb-lg {
  width: 128px;
  display: inline-block;
}

.thumb-md {
  width: 90px;
  display: inline-block;
}

.thumb-xs {
  width: 18px;
  display: inline-block;
}

.thumb-sm {
  width: 36px;
  display: inline-block;
}

.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.img-full {
  max-width: 100%;
}

.img-full>img {
  max-width: 100%;
}

.avatar {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
  border-radius: 500px;
  white-space: nowrap;
}

.avatar img {
  border-radius: 500px;
}

.avatar.border {
  border: 3px solid #fff;
  border-color: rgba(255, 255, 255, 0.5);
}

.clear {
  display: block;
  overflow: hidden;
}

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.no-touch .scroll-x,
.no-touch .scroll-y {
  overflow: hidden;
}

.no-touch .scroll-x:hover,
.no-touch .scroll-x:focus,
.no-touch .scroll-x:active {
  overflow-x: auto;
}

.no-touch .scroll-y:hover,
.no-touch .scroll-y:focus,
.no-touch .scroll-y:active {
  overflow-y: auto;
}

.no-touch .hover-action {
  display: none;
}

.no-touch .hover:hover .hover-action {
  display: inherit;
}

.h {
  font-size: 170px;
  font-weight: 300;
  background-image: -webkit-linear-gradient(92deg, #fb83fa, #00aced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (min-width: 992px) {
  .col-lg-2-4 {
      width: 20.000%;
      float: left;
  }
}

@media (max-width: 767px) {
  .shift {
      display: none !important;
  }
  .shift.in {
      display: block !important;
  }
  .row-2 [class*="col"] {
      width: 50%;
      float: left;
  }
  .row-2 .col-0 {
      clear: none;
  }
  .row-2 li:nth-child(odd) {
      clear: left;
      margin-left: 0;
  }
  .text-center-xs {
      text-align: center;
  }
  .pull-none-xs {
      float: none !important;
  }
  .hidden-xs.show {
      display: inherit !important;
  }
}



/**/
.edit {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.edit:focus {
  outline: none;
  border-color: #007bff;
}

li.active .btn-group .dropdown-toggle, button.btn-close-task {
  z-index: 999;
}

#task-list .list-group-item.active a {
  color: #333 !important;
}

#task-list li.done {
  background: #ccc;
}

#task-list li.passed {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

button.btn.btn-default:has(i.fa.fa-check) {
  background: #1DA049;
  border-color: #1DA049;
}

.react-colorful {
  width: 100%;
  height: 200px;
}

.list-group-item {
  min-height: 40px;
}

.list-group-item .edit {
  width: 100%; /* Убедитесь, что поле ввода занимает всю ширину */
  box-sizing: border-box;
  padding: 6px 12px; /* Соответствует padding полю ввода */
  line-height: 1.42857143; /* Соответствует line-height полю ввода */
  min-height: 40px;
}

.list-group-item .task-content {
  min-height: 40px; /* Убедитесь, что минимальная высота сохраняется */
}

